<template>

<v-card
    class="d-flex flex-column pa-2"
    flat
    tile
    height="100%"
  >
   
    <v-snackbar
      v-model="exibe_alerta"
      timeout="2000"
      :color="alerta_color"
    >
      {{ alerta }}
    </v-snackbar> 

    <div> 
      <v-toolbar flat dense>
        <v-app-bar-nav-icon class="d-lg-none ml-n6" @click.stop="$store.commit('switchSidebar');"></v-app-bar-nav-icon>
        <v-toolbar-title class="text-h5 ml-n5" >Área do Participante - {{ $store.state.pessoa_fisica.nome }}</v-toolbar-title>

      </v-toolbar> 
    </div>

     <!--
    #################################
    ## RECADASTRAMENTO
    #################################
    -->

    <v-row v-if="$store.state.recad == 2 && !loading">
      <v-col cols="12" >
        
          <v-alert  text class="text-h6" type="info" color="blue darken-4" >
            Começou o seu período de recadastramento! Clique <a class="primary--text" href="#" @click="$router.push('recad');"><b>aqui</b></a> e preencha o requerimento de recadastramento. O formulário já vem pré-preenchido, se seus dados já estiverem corretos você só precisa conferir e encaminhar! 
          </v-alert>
        
      </v-col>
    </v-row> 

    <v-row v-if="$store.state.recad == 3 && !loading">
      <v-col cols="12" >
        
        <v-alert  text class="text-h6" type="info" color="deep-orange" >
          Seu período de recadastramento está acabando! Clique <a class="primary--text" href="#" @click="$router.push('recad');"><b>aqui</b></a> e preencha o requerimento de recadastramento. Evite o bloqueio das demais funcionalidades da área do participante ou a retenção do seu benefício no caso dos aposentados e pensionistas! 
        </v-alert>
        
      </v-col>
    </v-row> 

    <v-row v-if="$store.state.recad == 4 && !loading">
      <v-col cols="12" >
        
        <v-alert  text class="text-h6" type="info" color="red darken-4" >
          Seu período de recadastramento se encerrou! Clique <a class="primary--text" href="#" @click="$router.push('recad');"><b>aqui</b></a> e preencha o requerimento de recadastramento!  
        </v-alert>
        
      </v-col>
    </v-row> 
       

    <!--
    ################################
     Quatro cards superiores
    ################################
    -->
    <div >
      <v-row v-if="!loading">
        <v-col cols="12" md="3">
          <v-card min-height="180px" max-height="180px" color="primary" flat>
            <v-card-title class="white--text">Plano 
              <v-spacer></v-spacer>
              <v-btn  icon v-if="$store.state.planos_usuario.length > 1" @click="TrocaPlano">
                <v-icon class="white--text">mdi-arrow-right-bold</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="white--text text-h4" >{{$store.state.planos_usuario[$store.state.index_plano_selecionado].sigla_plano}}</v-card-text>
            <v-card-text class="white--text" >Situação: {{ $store.state.planos_usuario[$store.state.index_plano_selecionado].situacao }}</v-card-text>

          </v-card>  
        </v-col>  
        <v-col cols="12" md="3">
          <v-card min-height="180px" max-height="180px" color="#218239" flat v-if="$store.state.planos_usuario[$store.state.index_plano_selecionado].situacao == 'SALDADO' || ($store.state.planos_usuario[$store.state.index_plano_selecionado].id_plano ==1 && $store.state.planos_usuario[$store.state.index_plano_selecionado].situacao == 'AUTOPATROCÍNIO')" >

            <v-card-title class="white--text">Valor BPA 
            </v-card-title>
            
            <v-card-text v-if="calculando_saldo">
              <v-progress-linear
                  indeterminate
                  color="#73ce7f"
                  striped
                  height="30"
                ></v-progress-linear>
            </v-card-text>
            <v-card-text v-if="!calculando_saldo" class="white--text text-h4 text-no-wrap" >{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(valor_bpa) }}</v-card-text>
            <v-card-text v-if="!calculando_saldo" class="white--text" >Atualizado até {{data_bpa.substr(8,2)+'/'+data_bpa.substr(5,2)+'/'+data_bpa.substr(0,4)}}</v-card-text>
          </v-card> 
          <v-card  min-height="180px" max-height="180px" color="#218239" flat v-else-if="$store.state.planos_usuario[$store.state.index_plano_selecionado].id_plano ==2 && ($store.state.planos_usuario[$store.state.index_plano_selecionado].situacao == 'ATIVO' || $store.state.planos_usuario[$store.state.index_plano_selecionado].situacao == 'AUTOPATROCÍNIO' || $store.state.planos_usuario[$store.state.index_plano_selecionado].situacao == 'BPD')">

            <v-card-title class="white--text">Saldo de contas
            </v-card-title>
            <v-card-text v-if="calculando_saldo">
              <v-progress-linear
                  indeterminate
                  color="#73ce7f"
                  striped
                  height="30"
                ></v-progress-linear>
            </v-card-text>
            <v-card-text v-if="!calculando_saldo" class="white--text text-h4 text-no-wrap" >{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(saldo) }}</v-card-text>
            
          </v-card> 
          <v-card min-height="180px" max-height="180px" color="#218239" flat v-else>
            <v-card-title class="white--text">Renda atual
            </v-card-title>
            <v-card-text v-if="calculando_saldo">
              <v-progress-linear
                  indeterminate
                  color="#73ce7f"
                  striped
                  height="30"
                ></v-progress-linear>
            </v-card-text>
            <v-card-text v-if="!calculando_saldo" class="white--text text-h4 text-no-wrap" >{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(valor_bnf) }}</v-card-text>
            
          </v-card> 
        </v-col>

        <v-col cols="12" md="3">
          <v-card min-height="180px" max-height="180px" color="#ff772b" flat >
            <v-card-title class="white--text">Limite de crédito
            </v-card-title>
            <v-card-text v-if="calculando_emprestimo">
              <v-progress-linear
                  indeterminate
                  color="#ffca7a"
                  striped
                  height="30"
                ></v-progress-linear> 
            </v-card-text>
            <v-card-text v-if="!calculando_emprestimo && limite_emprestimo > 0 " class="white--text text-h4 text-no-wrap" > {{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(limite_emprestimo) }} </v-card-text>
            <v-card-text v-if="!calculando_emprestimo && limite_emprestimo > 0 " class="white--text" >Faça uma simulação!</v-card-text>

            <v-card-text v-if="$store.state.id_emp ==2 && !calculando_emprestimo && limite_emprestimo <= 0 && ($store.state.planos_usuario[$store.state.index_plano_selecionado].situacao == 'ATIVO' || $store.state.planos_usuario[$store.state.index_plano_selecionado].situacao == 'SALDADO')" class="white--text" >
                      Já autorizou o Serpros a consultar sua margem consignável? Caso não tenha autorizado <a class="white--text" href="https://consignacao.serpro.gov.br" target="_blank"> clique aqui</a> e depois faça uma simulação </v-card-text>
            <v-card-text v-if="$store.state.id_emp ==1 && !calculando_emprestimo && limite_emprestimo <= 0" class="white--text text-h4 text-no-wrap" >
              R$ 0,00
            </v-card-text>

           <!-- <v-card-text v-if="!calculando_emprestimo && limite_emprestimo <= 0 && ($store.state.planos_usuario[$store.state.index_plano_selecionado].situacao == 'APOSENTADO' || $store.state.planos_usuario[$store.state.index_plano_selecionado].situacao == 'PENSIONISTA')" class="white--text" >Faça uma simulação! <router-link to="/contratacao" class="white--text">aqui</router-link>!</v-card-text> -->

          </v-card>  
          

        </v-col>  
        <v-col cols="12" md="3">
          <v-card min-height="180px" max-height="180px" color="#c22d87" flat v-if="$store.state.planos_usuario[$store.state.index_plano_selecionado].id_plano ==2 && ($store.state.planos_usuario[$store.state.index_plano_selecionado].situacao == 'ATIVO' || $store.state.planos_usuario[$store.state.index_plano_selecionado].situacao == 'AUTOPATROCÍNIO')">

            <v-card-title class="white--text">Rentabilidade
            </v-card-title>
            <v-card-text v-if="loading_g2">
              <v-progress-linear
                  indeterminate
                  color="#f561b4"
                  striped
                  height="30"
                ></v-progress-linear> 
            </v-card-text>
            <v-card-text v-if="!loading_g2" class="white--text text-h4" >{{valor_cota}}%</v-card-text>
            <v-card-text v-if="!loading_g2" class="white--text" >Mês: {{ref_cota.substr(5,2)+'/'+ref_cota.substr(0,4)}}</v-card-text>
          </v-card> 

          <v-card min-height="180px" max-height="180px" color="#c22d87" flat v-else>
            <v-card-title class="white--text">Taxa de juros
            </v-card-title>
            <v-card-text class="white--text"><span class="text-h4">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format($store.state.taxa_emprestimo) }}%</span> ao mês</v-card-text>
            <v-card-text class="white--text" >Para empréstimos de 01 até 12 meses</v-card-text>
          </v-card> 
        </v-col>
      </v-row> 
    </div>


    <!--
    ################################
     Dois cards grandes no centro
    ################################
    -->
    <div  >
      <v-row class="mt-3">
        <v-col cols="12" md="6" v-if="$store.state.planos_usuario[$store.state.index_plano_selecionado].id_plano ==2 && $store.state.planos_usuario[$store.state.index_plano_selecionado].situacao != 'ASSISTIDO' && !loading">
          <v-card min-height="360px"  color="#EEEEEE" flat>
            <v-card-title>Extrato de contas consolidado</v-card-title>
            <v-card-text>

                <v-row v-if="calculando_saldo" class="mt-12">
                  <v-col>
                    <div class="text-center" >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </div>
                  </v-col>
                </v-row>  
                <v-row v-if="calculando_saldo" >
                  <v-col>
                    <div class="text-center" >Carregando</div>
                  </v-col>
                </v-row>
                <v-row v-if="calculando_saldo" class="mt-10">
                  <v-col>
                    <div class="text-center" >&nbsp;</div>
                  </v-col>
                </v-row>
                <v-row v-if="calculando_saldo" class="mt-10">
                  <v-col>
                    <div class="text-center" >&nbsp;</div>
                  </v-col>
                </v-row>
                                <v-row v-if="calculando_saldo" class="mt-10">
                  <v-col>
                    <div class="text-center" >&nbsp;</div>
                  </v-col>
                </v-row>
              
                <GChart
                  type="PieChart"
                  :data="grafico1_data"
                  :options="grafico1_options" 
                  v-if="!calculando_saldo"
                />  
            </v-card-text>
          </v-card>  
        </v-col>  

        <!-- v-if="$store.state.planos_usuario[$store.state.index_plano_selecionado].id_plano ==2 && $store.state.planos_usuario[$store.state.index_plano_selecionado].situacao == 'ATIVO' && !loading" -->
        
        <!-- LOYO - comentei o trecho abaixo -->

        <!-- <v-col cols="12" md="6" v-if="$store.state.planos_usuario[$store.state.index_plano_selecionado].id_plano ==2 && $store.state.planos_usuario[$store.state.index_plano_selecionado].situacao != 'ASSISTIDO' && !loading">
          <v-card min-height="440px" color="#EEEEEE" flat >
            <v-card-title>Rentabilidade do PSII nos últimos  12 meses</v-card-title>
            <v-card-text>

              <v-row v-if="loading_g2" class="mt-5"> 
                <v-col>
                  <div class="text-center" >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-col>
              </v-row>  
              <v-row v-if="loading_g2" >
                <v-col>
                  <div class="text-center" >Carregando</div>
                </v-col>
              </v-row>
              <v-row v-if="calculando_saldo" class="mt-10">
                <v-col>
                  <div class="text-center" >&nbsp;</div>
                </v-col>
              </v-row>
              
              <GChart
                type="AreaChart"
                :data="grafico2_data"
                :options="grafico2_options"
                v-if="!loading_g2"
              />  
            </v-card-text>
          </v-card> 
        </v-col> -->

        <!-- LOYO - comentei o trecho acima -->

        <!-- LOYO -->

        <v-col cols="12" md="6" v-if="!loading">
          <v-card min-height="440px" color="#EEEEEE" flat >
            <v-card-title>Aviso</v-card-title>
            <v-card-text>

              <v-row v-if="loading_g2" class="mt-5"> 
                <v-col>
                  <div class="text-center" >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-col>
              </v-row>  
              <v-row v-if="loading_g2" >
                <v-col>
                  <div class="text-center" >Carregando</div>
                </v-col>
              </v-row>
              <v-row v-if="!loading_g2"> 
                <v-col>
                    <p>
                        <strong>
                            Olá, Participante!
                        </strong>
                    </p>
                    <p>
                        Você sabia que para cada real que você contribui para o seu plano de previdência, a Patrocinadora também contribui com o mesmo valor? Isso é o que chamamos de <strong>Paridade Contributiva</strong>. 
                    </p>
                    <p>
                        <strong>
                            Você deve estar se perguntando se há um limite para essa paridade, não é mesmo? 
                        </strong>
                    </p>
                    <p>
                        Sim, existem limites. 
                    </p>
                    <p>
                        Primeiramente, é importante entender que no Plano PS-II existem diferentes tipos de contribuições que contam com a paridade da sua Patrocinadora, vamos entendê-las: 
                    </p>
                    <p>
                        <u>Contribuição Risco</u> – Essa contribuição é obrigatória e seu valor depende de sua idade quando você adere ao plano;<br>
                        <u>Contribuição Básica</u> - Também é obrigatória, e o valor corresponde à 1% fixo de seu Salário de Contribuição; <br>
                        <u>Contribuição Variável</u> - Essa é opcional. Você decide o valor, que pode variar de 0% até 15% do seu Salário de Contribuição, já deduzido de 8VRS. 
                    </p>
                    <p>
                        É importante também observar o <u>limite</u> global estabelecido para a <u>Patrocinadora</u>, que é <u>de 10% sobre a soma dos Salários de Contribuição de todos os participantes</u>. Se esse limite for ultrapassado, conforme prevê o regulamento, a Patrocinadora reduzirá proporcionalmente as contribuições até que o total esteja dentro do limite. 
                    </p>
                    <p>
                        <strong>Vamos entender melhor como isso funciona na prática? </strong>
                    </p>
                    <p>
                        <strong>
                            Clique <a href="https://serpros.com.br/2024/04/16/entenda-a-paridade-das-contribuicoes-no-psii/" target="_blank">
                                aqui
                            </a> para acessar o conteúdo que preparamos para explicar esse tema. 
                        </strong>
                    </p>
                </v-col>
                            
              </v-row>
              
              <!-- <v-row v-if="calculando_saldo" class="mt-10">
                <v-col>
                  <div class="text-center" >&nbsp;</div>
                </v-col>
              </v-row>
              
              <GChart
                type="AreaChart"
                :data="grafico2_data"
                :options="grafico2_options"
                v-if="!loading_g2"
              />   -->
            </v-card-text>
          </v-card> 
        </v-col>

        <!-- LOYO -->


        <v-col cols="12" md="6" v-if="  !loading && 1==2">
          <v-card min-height="360px"  color="#EEEEEE" flat>
            <v-card-title>Eleições Serpros 2023</v-card-title>
            <v-card-text>
              <v-row v-if="loading_eleicoes" class="mt-5">
                  <v-col>
                    <div class="text-center" >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </div>
                  </v-col>
                </v-row>  
                <v-row v-if="loading_eleicoes" >
                  <v-col>
                    <div class="text-center" >Carregando</div>
                  </v-col>
                </v-row>
                <v-row v-if="loading_eleicoes" class="mt-10">
                <v-col>
                  <div class="text-center" >&nbsp;</div>
                </v-col>
              </v-row>
              <v-row v-if="loading_eleicoes" class="mt-10">
                <v-col>
                  <div class="text-center" >&nbsp;</div>
                </v-col>
              </v-row>
              <v-row v-if="loading_eleicoes" class="mt-10">
                <v-col>
                  <div class="text-center" >&nbsp;</div>
                </v-col>
              </v-row>
              <v-row v-if="!loading_eleicoes">
                <v-col cols="12" md="6" align-self="end">
                  <v-img
                    contain
                    src="/img/eleicao.jpg"
                    max-height="360"
                    min-height="360"
                    min-width="140"
                  >
                  </v-img>
                </v-col>
                <v-col cols="12" md="6" align-self="end">
                  <div class="text-justify text-subtitle-2">O Processo Eleitoral Serpros 2023 começou e a inscrição e votação 
                      serão realizadas através da Área do Participante. Acompanhe o cronograma 
                      oficial no endereço eleicoes.serpros.com.br. 
                      Para informações sobre os tratamentos de dados pessoais, 
                      acesse o <a class="font-weight-bold indigo--text " href="https://serpros.com.br/wp-content/uploads/2023/06/aviso_privacidade_eleicoes_2023.pdf" target="_blank">Aviso de privacidade.</a>
                  </div>
                  <div>&nbsp;</div>
                  <div>&nbsp;</div>
                  <v-btn block color="primary" dark @click="eleicoes">
                    Participe
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>  
        </v-col> 


        <v-col cols="12" md="6" v-if="(($store.state.planos_usuario[$store.state.index_plano_selecionado].id_plano ==2 && $store.state.planos_usuario[$store.state.index_plano_selecionado].situacao == 'ASSISTIDO') || $store.state.planos_usuario[$store.state.index_plano_selecionado].id_plano ==1) && !loading">
          <v-card min-height="360px"  color="#EEEEEE" flat>
            <v-card-title>Conheça o plano Ser+</v-card-title>
            <v-card-text>
              <v-row align-self="end">
                <v-col cols="12" md="6" align-self="end">
                  <v-img
                    contain
                    src="/img/LOGO-SER.png"
                    max-height="360"
                    min-height="360"
                    min-width="140"
                  >
                  </v-img>
                </v-col>
                <v-col cols="12" md="6" align-self="end">
                  <div class="text-justify text-subtitle-2">Um novo produto, um novo investimento, uma nova forma de proteger o futuro da sua família: Conheça o plano Ser+ o plano família do Serpros! Este plano foi feito para os participantes do Serpros e também para seus parentes até terceiro grau. A adesão está aberta para a família toda! 
                  </div>
                  <div>&nbsp;</div>
                  <div>&nbsp;</div>
                  <a class="font-weight-bold indigo--text " href="https://planosermais.serpros.com.br/" target="_blank">
                    <v-btn block color="primary" dark>
                      Saiba mais
                    </v-btn>
                  </a>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>  
        </v-col> 

        <v-col cols="12" md="6" v-if="(($store.state.planos_usuario[$store.state.index_plano_selecionado].id_plano ==2 && $store.state.planos_usuario[$store.state.index_plano_selecionado].situacao == 'ASSISTIDO') || $store.state.planos_usuario[$store.state.index_plano_selecionado].id_plano ==1) && !loading">
          <v-card min-height="360px"  color="#EEEEEE" flat>
            <v-card-title>Destaque:</v-card-title>
            <v-card-text>
              <v-row align-self="end">
                <v-col cols="12" md="6" align-self="end">
                  <v-img
                    contain
                    src="/img/Destaque_1.png"
                    max-height="360"
                    min-height="360"
                    min-width="140"
                  >
                  </v-img>
                </v-col>
                <v-col cols="12" md="6" align-self="end">
                  <div class="text-justify text-subtitle-2"><b>Serpros encerra exercício de 2023 superando a meta atuarial.</b> Resultado é alcançado tanto nos investimentos quanto no resultado Contábil dos Planos PS-I, PS-II e na meta do PGA. 
                  </div>
                  <div>&nbsp;</div>
                  <div>&nbsp;</div>
                  <a class="font-weight-bold indigo--text " href="https://serpros.com.br/2024/02/02/serpros-encerra-exercicio-de-2023-superando-a-meta-atuarial/" target="_blank">
                    <v-btn block color="primary" dark>
                      Leia aqui a matéria completa
                    </v-btn>
                  </a>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>  
        </v-col> 
      </v-row>  
    </div> 

   

    <v-row v-if="loading" class="mt-5">
      <v-col>
        <div class="text-center" >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>  
    <v-row v-if="loading" >
      <v-col>
        <div class="text-center" >Carregando</div>
      </v-col>
    </v-row> 


   

    <!--
    ################################
     Rodapé
    ################################
    -->
    <div class="mt-auto">
      <v-row class="mt-3">
        <v-col cols="12">
          <v-card  color="grey lighten-1 text-center" flat>
            <v-card-text >
              Fale conosco pelo endereço <a class="font-weight-bold grey--text text--darken-3" href="https://serpros.com.br/fale-conosco" target="_blank">www.serpros.com.br/fale-conosco</a> ou pelo telefone 0800 721 10 10 das 09h às 18h <br>
              Desenvolvido pela Gerência de Tecnologia - GETEC. Versão {{ $store.state.versao }}
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-overlay
      absolute
      :value="dialog"
    >
    </v-overlay>

    <v-snackbar
      v-model="dialog"
      multi-line
      centered
      :color="cor_dialogo"
      timeout="-1"
      max-width="400"
      min-height="140"
      vertical
    >
      {{ mensagem }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="BtnOk(titulo)"
          v-if="!sim_nao"
        >
          Ok
        </v-btn>
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="BtnSim(titulo)"
          v-if="sim_nao"
        >
          Sim
        </v-btn>
                <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="BtnNao(titulo)"
          v-if="sim_nao"
        >
          Não
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>

</template>

<script>
   import { GChart } from "vue-google-charts/legacy";
   
   export default {
      components: {
        GChart
      },
      data: function(){
         return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            multiLine: true,
            absolute: false,
            form_valido: true,
            loading: true,
            loading_g2: false,
            calculando_saldo: false,
            calculando_emprestimo: false,
            loading_eleicoes: false,
            mensagem: '',
            dialog: false,
            cor_dialogo: '#B00020',
            titulo: null,
            sim_nao: false,
            reverse: false,
            resposta: false,
            exibe_alerta: false,
            alerta_color: "green",
            alerta: '',
            limite_emprestimo: 0,
            contratos: [],
            saldos:[],
            saldo: 0,
            valor_bpa:0,
            data_bpa: '',
            valor_bnf: 0,
            ref_cota: '',
            valor_cota: 0,
            consentimento_lgpd:false,
            somente_leitura: true,
            grafico1_data: [
              ['Conta', 'saldo']
            ],
            grafico1_options: {
              height: 360,
              backgroundColor: 'transparent',
              chartArea:{width:'100%',height:'100%'},
              //pieSliceText: 'value',
              sliceVisibilityThreshold: 0,
              //enableInteractivity: false,
              legend: {textStyle: {fontSize: 14}},
              is3D: true,
              pieSliceTextStyle: { fontSize: 18},
              colors:['#0084c2','#218239','#ff772b','#c22d87','#00aeef','#829fbd','#8c9eff']
            },
            grafico2_data: [
              ['Mês','Variação acumulada'], 
              ['1',0],
              ['2',0],
              ['3',0],
              ['4',0],
              ['5',0],
              ['6',0],
              ['7',0],
              ['8',0],
              ['9',0],
              ['10',0],
              ['11',0],
              ['12',0],

              ],
            grafico2_options: {
              height: 360,
              backgroundColor: '#eeeeee',
              hAxis: {slantedText:true},
              vAxis: {format:'percent' },
              legend:{position: 'none'},
              chartArea:{width:'80%',height:'70%'}, 

            }
            
         }
      },
      created: function() {

        if(this.$store.state.atendente == null){
          this.somente_leitura = false;
        }

        var texto = '';
        this.$store.dispatch('exibeajuda',texto);

        this.loading = false;

        if(this.$store.state.alerta != ''){
           this.alerta = this.$store.state.alerta;
           this.$store.commit('setalerta','');
           this.alerta_color = "green";
           this.exibe_alerta = true;
        }

        this.iniciaSimuladorEmprestimo();
        this.Painel2();
        this.variacaoCota();

        
      },
      methods: {

        //#####################
        //click dos botões da tela
        //####################
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 

        BtnSim(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        }, 


        sair(){

          var usuario = {
                logado: false,
                nome: '',
                id: '',
                email: '',
                matricula: '',
                id_setor: ''
              };

          this.$store.commit('setusuariologado', usuario);

          this.$router.push('/login');
         

        }, 


        
        perguntaSair(){

          this.titulo = 'Sair';
          this.mensagem = 'Deseja sair do sistema?';
          this.cor_dialogo = 'primary';
          this.sim_nao = true;
          this.dialog = true;

        },


        TrocaPlano(){
          
          if(this.$store.state.index_plano_selecionado == (this.$store.state.planos_usuario.length -1) ){
            this.$store.state.index_plano_selecionado = 0;
          }else{
            this.$store.commit('setplanoselecionado', this.$store.state.index_plano_selecionado + 1);
          }
          this.Painel2();
          this.iniciaSimuladorEmprestimo();
          this.variacaoCota();

        },
        //#####################
        //funções utilitárias
        //####################

        siglaPlano(){
      
        var id_plano = parseInt(this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado].id_plano);

        var plano = this.$store.state.planos.find(function(plano) {
            
            return plano.id == id_plano
          });

        return plano.sigla;
       
        },

        Painel2(){

          var plano = this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado];

          this.valor_bnf = 0;
          this.saldo = 0;
          this.valor_bpa = 0;

          if(plano.id_plano == 2 && (plano.situacao == 'ATIVO' || plano.situacao == 'BPD' || plano.situacao == 'AUTOPATROCÍNIO') ){
             this.SaldoDeContas();
          }
          if(plano.id_plano == 1 && (plano.situacao == 'SALDADO' || plano.situacao == 'BPD' || plano.situacao == 'AUTOPATROCÍNIO') ){
             this.ValorBPA();
          }
          if(plano.situacao == 'ASSISTIDO' || plano.situacao == 'PENSIONISTA' || plano.situacao == 'AUXÍLIO DOENÇA' || plano.situacao == 'ACIDENTE DE TRABALHO'){
             this.ValorBeneficio();
          }

          
        },



        iniciaSimuladorEmprestimo(){

          var plano = this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado];

          this.id_plano = plano.id_plano;

          var params = {
            id_pessoa: plano.id_pessoa,
            id_plano: plano.id_plano,
            qtd_parcelas: 1,
            plano_situacao: plano.situacao,
            id_pessoa_titular: plano.id_pessoa_titular
          }

          


          this.calculando_emprestimo = true;
          this.$API.post( '/api/emprestimo/inicia', params)
          .then(response => {

              

              this.contratos =  response.data.contratos; 
              //var valor_max = Math.floor(response.data.max_liquido);
              var idade = response.data.idade;
              var valor_max = response.data.max_bruto;

              this.limite_emprestimo = valor_max;
              
              if(this.margem == 'erro'){
                 this.limite_emprestimo = 0;
              }

              var cobranca_judicial = 'N';
              
              for (var i=0; i < this.contratos.length; i++)  {
                  this.contratos[i].selecionado =false;
                  if(this.contratos[i].ic_controle_judicial == 'S'){
                      cobranca_judicial = 'S';
                  }
              }

              if(cobranca_judicial == 'S'){
                this.limite_emprestimo = 0;
              }

              if(idade < 18){
                this.limite_emprestimo = 0;
              }
                 
               this.calculando_emprestimo = false;   
              

              
          })
          .catch(error => {
              console.log(error.response);
              this.loading = false;  
          }); 
           

        },

        SaldoDeContas(){

          var plano = this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado];

          var params = {
            id_pessoa_participante: plano.id_pessoa,
            id_plano_participante: plano.id_plano,
          }

            this.calculando_saldo = true;
            this.data_cota = '';
            
            this.$API.post( '/api/arr/saldos', params)
            .then(response => {
                 
                this.saldos = response.data.saldos;
                this.data_cota = response.data.data_cota;
                this.saldo = 0;
                this.grafico1_data = [];
                this.grafico1_data.push(['conta','Saldo']);

                

                for (var i=0, x = this.saldos.length; i < x; i++)  {


                    this.grafico1_data.push([this.saldos[i].nm_conta, {v:this.saldos[i].saldo_reais, f:Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(this.saldos[i].saldo_reais)}]);

                    this.saldo = this.saldo + this.saldos[i].saldo_reais;
                }

                this.calculando_saldo = false;
                
                
            })
            .catch(error => {
                this.TrataErros(error);
            }); 

        },

        ValorBPA(){

          var plano = this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado];

          var params = {
            id_pessoa: plano.id_pessoa,
            id_plano: plano.id_plano,

          }

            this.calculando_saldo = true;
            this.data_cota = '';
            
            this.$API.post( '/api/bpa/consultar', params)
            .then(response => {
                 
                this.valor_bpa = response.data.valor;
                this.data_bpa = response.data.data_referencia;

                this.calculando_saldo = false;
                
                
            })
            .catch(error => {
                this.TrataErros(error);
            }); 

        },

        ValorBeneficio(){

          var plano = this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado];

          var params = {
            id_pessoa: plano.id_pessoa,
            id_plano: plano.id_plano,
            plano_situacao: plano.situacao,
          }

            this.calculando_saldo = true;
            
            this.$API.post( '/api/bnf/renda', params)
            .then(response => {
                 
                this.valor_bnf = response.data;

                this.calculando_saldo = false;
                
                
            })
            .catch(error => {
                this.TrataErros(error);
            }); 

        },

        variacaoCota(){

          var plano = this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado];

          var indexador = '';
          if(plano.id_plano == 1){
            indexador = 'FACRES';
          }else{
            indexador = 'FACPS2'
          }

          var params = {
            indexador: indexador
            
          }

            this.loading_g2 = true;
            
            this.$API.post( '/api/arr/indexador12', params)
            .then(response => {

                this.grafico2_data = [];
                this.grafico2_data.push(['Mês','Variação acumulada']);
                
                for (var i=0, x = response.data.length; i < x; i++)  {
                    
                     this.grafico2_data.push([{v:response.data[i].dt_indexador, f:response.data[i].dt_indexador.substr(5,2)+"/"+response.data[i].dt_indexador.substr(2,2)}, {v:response.data[i].variacao_acumulada/100, f:Intl.NumberFormat('pt-BR',{style:'percent', minimumFractionDigits: 2}).format(response.data[i].variacao_acumulada/100)}]);

                }

                if(response.data.length > 0){
                    this.ref_cota = response.data[response.data.length-1].dt_indexador;
                    this.valor_cota = Intl.NumberFormat('pt-BR',{style:'decimal', minimumFractionDigits: 2}).format(response.data[response.data.length-1].variacao)   ;
                }
                
                this.loading_g2 = false;
                
            })
            .catch(error => {
                this.TrataErros(error);
            }); 

        },


        eleicoes(){

          let paginaEleicoes = window.open('', '_blank');
          let paginaLoading = '<p style="text-align:center"><span style="font-size:18px; font-family: Arial, Helvetica, sans-serif; color: #404040;"><strong>Carregando Sistema de Eleições ...</strong></span></p>';
          paginaEleicoes.document.write(paginaLoading);


          this.loading_eleicoes = true;

          let plano = this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado];

          let params = {
            id_pessoa: plano.id_pessoa,
            id_plano: plano.id_plano
          }

          this.$API.post( '/api/aut/eleicoes', params)
          .then(response => {

            if(response.data.codigo == "ok"){

              let token = response.data.token; 
              token = token.substr(1,token.length-2);

              paginaEleicoes.location.href = process.env.VUE_APP_URL_ELEICOES+"/IntegracaoSerpros.aspx?token="+token;
      
              this.loading_eleicoes = false;
            }else{
                this.alerta_color = "#B00020";
                this.alerta = response.data.msg;
                this.exibe_alerta = true;
                this.loading_eleicoes = false;
            }
            
                          
          }); 

        },

        consentimento(){

          this.loading = true;

          let params = {
            cpf: this.$store.state.pessoa_fisica.cpf,
          }

          this.$API.post( '/api/consentimento/salvar', params)
          .then(response => {
            
            this.loading = false;  
            this.$store.commit('setconsentimento', true);
            this.msg_ok = 'Consentimento cadastrado com sucesso!';

          })
          .catch(error => {
              this.TrataErros(error);
          });


        },



        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        } 

      },
   }
</script>