<template>

<v-card
    class="d-flex flex-column pa-2"
    flat
    tile
    height="100%"
  >
   
    

    <div> 
      <v-toolbar flat dense>
        <v-app-bar-nav-icon class="d-lg-none ml-n6" @click.stop="$store.commit('switchSidebar');"></v-app-bar-nav-icon>
        <v-toolbar-title class="text-h5 ml-n5" >Área do Participante - {{ $store.state.pessoa_fisica.nome }}</v-toolbar-title>

      </v-toolbar> 
    </div>

     <!--
    #################################
    ## RECADASTRAMENTO
    #################################
    -->

    <v-row v-if="$store.state.recad_analise=='N'">
      <v-col cols="12" >
        
          <v-alert  text class="text-h6" type="info" color="red darken-4" >
            Seu período de recadastramento se encerrou! Clique <a class="primary--text" href="#" @click="$router.push('recad');"><b>aqui</b></a> e preencha o requerimento de alteração de dados cadastrais! 
          </v-alert>
        
      </v-col>
    </v-row> 


    <v-row v-if="$store.state.recad_analise=='S'">
      <v-col cols="12" >
        
          <v-alert  text class="text-h6" type="info" color="blue darken-4" >
            Você encaminhou um requerimento de alteração de dados cadastrais para o Serpros. Clique <a class="primary--text" href="#" @click="$router.push('recad');"><b>aqui</b></a> para acompanhar o andamento do seu requerimento! 
          </v-alert>
        
      </v-col>
    </v-row> 
    

    

   

    <v-row v-if="loading" class="mt-5">
      <v-col>
        <div class="text-center" >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>  
    <v-row v-if="loading" >
      <v-col>
        <div class="text-center" >Carregando</div>
      </v-col>
    </v-row> 


    <v-overlay
      absolute
      :value="dialog"
    >
    </v-overlay>

    <v-snackbar
      v-model="dialog"
      multi-line
      centered
      :color="cor_dialogo"
      timeout="-1"
      max-width="400"
      min-height="140"
      vertical
    >
      {{ mensagem }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="BtnOk(titulo)"
          v-if="!sim_nao"
        >
          Ok
        </v-btn>
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="BtnSim(titulo)"
          v-if="sim_nao"
        >
          Sim
        </v-btn>
                <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="BtnNao(titulo)"
          v-if="sim_nao"
        >
          Não
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>

</template>

<script>
   import { GChart } from "vue-google-charts/legacy";
   
   export default {
      components: {
        GChart
      },
      data: function(){
         return {
            
            multiLine: true,
            absolute: false,
            loading: true,
            mensagem: '',
            dialog: false,
            cor_dialogo: '#B00020',
            titulo: null,
            sim_nao: false,
            reverse: false,
            resposta: false,
            exibe_alerta: false,
            alerta_color: "green",
            alerta: '',
            
         }
      },
      created: function() {


        this.loading = false;
        
      },
      methods: {

        //#####################
        //click dos botões da tela
        //####################
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 

        BtnSim(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        }, 


        sair(){

          var usuario = {
                logado: false,
                nome: '',
                id: '',
                email: '',
                matricula: '',
                id_setor: ''
              };

          this.$store.commit('setusuariologado', usuario);

          this.$router.push('/login');
         

        }, 


        
        perguntaSair(){

          this.titulo = 'Sair';
          this.mensagem = 'Deseja sair do sistema?';
          this.cor_dialogo = 'primary';
          this.sim_nao = true;
          this.dialog = true;

        },


        



        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        } 

      },
   }
</script>