<template>
  <div>
    <v-snackbar
    v-model="exibe_alerta"
    timeout="4000"
    :color="alerta_color"
    >
    {{ alerta }}
    </v-snackbar> 

    <v-container fluid >
      <v-row >
        <v-col >
          <!-- Esse card é a imagem de fundo -->
          <v-card
            class="rounded-0"
            flat
          >
            <v-img
              src="img/saco_dinheiro.png"
            >
              <v-toolbar flat dense tile >
                <v-app-bar-nav-icon class="d-lg-none ml-n6" @click.stop="$store.commit('switchSidebar');"></v-app-bar-nav-icon>
                <v-toolbar-title class="text-h5 ml-n5"  >{{titulo_pagina}}</v-toolbar-title>
              </v-toolbar>


              <v-row v-if="loading" class="mt-5">
                <v-col>
                  <div class="text-center" >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-col>
              </v-row>  
              <v-row v-if="loading" >
                <v-col>
                  <div class="text-center" >Carregando</div>
                </v-col>
              </v-row> 

              <v-form ref="form" v-model="form_valido" lazy-validation> 
            
       
                <v-row v-if="!loading && !exibe_detalhes && !exibe_novo">
                  <v-col cols="12" md="12" >
                    <v-card-text>     

                      <v-row >
                        <v-col cols="12">
                          <v-data-table
                            :headers="headerChamados"
                            :items="chamados"
                            class="table"
                            no-data-text="Não existem chamados."
                            :items-per-page="-1"
                            :loading="loading"   
                            loading-text="Carregando chamados..."
                            hide-default-footer
                            >
                            
                            <template v-slot:item.created_at="{ item }">
                              <span>{{item.created_at.substring(8,10)+'/'+item.created_at.substring(5,7)+'/'+item.created_at.substring(0,4)}}</span>
                            </template>
                            

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn class="no-uppercase" text @click="exibedetalhes(item.id)">Detalhes</v-btn>
                            </template>


                            <template v-slot:item.dt_encerramento="{ item }">
                              <span>{{item.dt_encerramento != '' ? item.dt_encerramento.substring(8,10)+'/'+item.dt_encerramento.substring(5,7)+'/'+item.dt_encerramento.substring(0,4) : ''}}</span>
                            </template>

                          </v-data-table>
                        </v-col>
                      </v-row> 

                    </v-card-text>
                  </v-col>
                  
                </v-row>  


                <v-row v-if="!loading && exibe_detalhes">
                  <v-col cols="12" md="12" >
                    <v-card-text>
                      <v-card flat >
                        <v-card-text>     

                          <v-row >
                            <v-col cols="12" md="6"> 
                                <v-text-field readonly  dense label="Descrição" v-model="detalhes.descricao_macro" ></v-text-field>
                            </v-col> 
                            <v-col cols="12" md="3"> 
                                <v-text-field readonly  dense label="Assunto" v-model="detalhes.assunto" ></v-text-field>
                            </v-col> 
                            <v-col cols="12" md="3"> 
                                <v-text-field readonly  dense label="Data de abertura" v-model="detalhes.created_at.substring(8,10)+'/'+detalhes.created_at.substring(5,7)+'/'+detalhes.created_at.substring(0,4)" ></v-text-field>
                            </v-col> 
                          </v-row> 

                          <v-row >
                            
                            <v-col cols="12" md="3"> 
                                <v-text-field readonly  dense label="Protocolo" v-model="detalhes.numero" ></v-text-field>
                            </v-col> 
                            <v-col cols="12" md="3"> 
                                <v-text-field readonly  dense label="Situação" v-model="detalhes.situacao" ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" v-if="detalhes.descricao_detalhada != ''"> 
                                <v-btn depressed v-if="!exibe_descricao" @click="exibe_descricao = true;" >Exibir descrição detalhada</v-btn>
                                <v-btn depressed v-if="exibe_descricao" @click="exibe_descricao = false;" >Ocultar descrição detalhada</v-btn>
                            </v-col> 
                            
                          </v-row>

                          <v-row class="mt-n5" v-if="exibe_descricao">
                            <v-col cols="12"> 
                                <v-textarea readonly  dense  v-model="detalhes.descricao_detalhada" ></v-textarea>
                            </v-col> 
                            
                          </v-row>

                          <v-row >
                            <v-col cols="12"> 
                                <div class="text-h6">RESPOSTA DO SERPROS</div>
                            </v-col> 
                            
                          </v-row>

                          <v-row class="mt-n5">
                            <v-col cols="12"> 
                                <v-textarea readonly  dense  v-model="detalhes.resposta_serpros" ></v-textarea>
                            </v-col> 
                            
                          </v-row>

                        </v-card-text>

                        <v-card-text>     
                          <v-toolbar flat dense tile >
                            <v-spacer></v-spacer>
                            <v-btn rounded class="mr-3 " color="tertiary"   @click="exibe_detalhes = false">Voltar </v-btn>
                          </v-toolbar> 
                        </v-card-text> 
                      </v-card>
                    </v-card-text> 
                  </v-col>
                  
                </v-row>  

                <v-row v-if="!loading && exibe_novo">
                  <v-col cols="12" md="12" >
                    <v-card-text>
                      <v-card flat >
                        <v-card-text> 

                          <v-row class="mt-n5">
                            <v-col cols="12" > 
                                <div class="text-h6">Título</div>
                            </v-col> 
                          </v-row>
                          <v-row class="mt-n5">
                            <v-col cols="12" > 
                                <v-text-field :readonly="somente_leitura"  dense outlined v-model="chamado.descricao_macro"  :rules="[...Rules.Required, ...maxLength(128)]"></v-text-field>
                            </v-col> 
                          </v-row> 

                          <v-row class="mt-n5">
                            <v-col cols="12" > 
                                <div class="text-h6">Descrição</div>
                            </v-col> 
                          </v-row>

                          <v-row class="mt-n5">
                            <v-col cols="12" > 
                                
                                <v-textarea
                                  :readonly="somente_leitura"
                                  v-model="chamado.descricao_detalhada"
                                  auto-grow
                                  outlined
                                  rows="4"
                                  :rules="[...Rules.Required, ...maxLength(4000)]"
                                  counter
                                ></v-textarea>
                            </v-col> 
                          </v-row>

                        </v-card-text>

                        <v-card-text>     
                          <v-toolbar flat dense tile >
                            <v-spacer></v-spacer>
                            <v-btn rounded class="mr-3 " color="tertiary"   @click="exibe_novo = false; titulo_pagina = 'Meus chamados';">Voltar </v-btn>
                            <v-btn rounded class="mr-3 " color="primary"   @click="salvarNovoChamado">Salvar </v-btn>
                          </v-toolbar> 
                        </v-card-text> 
                      </v-card>
                    </v-card-text> 
                  </v-col>
                  
                </v-row>  


                <v-row v-if="!loading && !exibe_novo && !exibe_detalhes">
                  <v-col cols="12" md="12" >
                  <v-card-text>
                    <div class="text-right">
                        <v-btn rounded class="mr-3 " color="primary"   @click="novoChamado">Novo chamado </v-btn>
                    </div>
                  </v-card-text>

                  </v-col>
                  
                </v-row> 
              </v-form>


              <v-overlay
                absolute
                :value="dialog"
              >
              </v-overlay>

              <v-snackbar
                v-model="dialog"
                multi-line
                centered
                :color="cor_dialogo"
                timeout="-1"
                max-width="400"
                min-height="140"
                vertical
              >
                {{ mensagem }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnOk(titulo)"
                    v-if="!sim_nao"
                  >
                    Ok
                  </v-btn>
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnSim(titulo)"
                    v-if="sim_nao"
                  >
                    Sim
                  </v-btn>
                          <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnNao(titulo)"
                    v-if="sim_nao"
                  >
                    Não
                  </v-btn>
                </template>
              </v-snackbar> 
            </v-img>
          </v-card>
        </v-col>
      </v-row>      
    </v-container>
  </div>
</template>

<script>
   export default {
      data: function(){
         return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            titulo_pagina: "Meus Chamados",
            titulo: '',
            loading: true,
            form_valido:true,
            mensagem: '',
            dialog: false,
            cor_dialogo: '#B00020',
            exibe_alerta: false,
            alerta: '',
            alerta_color: "green",
            sim_nao: false,
            chamados: [],
            assuntos: [],
            somente_leitura: true,
            exibe_detalhes: false,
            exibe_novo: false,
            exibe_descricao: false,
            chamado: {
              cpf: this.$store.state.pessoa_fisica.cpf,
              descricao_macro: '',
              descricao_detalhada: '',
              id_setor: '200',
              id_canal: 1,
              id_prioridade: 1,
              id_assunto: null,
              nome: this.$store.state.pessoa_fisica.nome,
              email: this.$store.state.pessoa_fisica.email_pessoal,
              telefone: this.$store.state.pessoa_fisica.celular
            },
            detalhes:{
            },
            headerChamados: [
                {
                    text: 'Protocolo',
                    align: 'start',
                    sortable: false,
                    value: 'numero',
                    width: 100,
                    class: 'title white--text'
                },
                {
                    text: 'Data de abertura',
                    sortable: false,
                    value: 'created_at',
                    class: 'title white--text'
                },
                {
                    text: 'Título',
                    value: 'descricao_macro',
                    sortable: false,
                    class: 'title white--text'
                },
                {
                    text: 'Situação',
                    value: 'situacao',
                    sortable: false,

                    class: 'title white--text'
                },
                {
                    text: 'Data de encerramento',
                    sortable: false,
                    value: 'dt_encerramento',
                    class: 'title white--text'
                },
                {
                    text: '',
                    value: 'actions',
                    align: 'end',
                    sortable: false,
                   
                    class: 'title white--text'
                },
            ],
         }
      },

      created: function() {

        if(this.$store.state.atendente == null){
          this.somente_leitura = false;
        }

        let params = {
            api: this.$API,
            descricao: this.titulo_pagina
        }

        this.$store.dispatch('logatendimento', params);

        var texto = '';
        this.$store.dispatch('exibeajuda',texto);

        if(this.$store.state.alerta != ''){
           this.alerta = this.$store.state.alerta;
           this.$store.commit('setalerta','');
           this.exibe_alerta = true;
        }

        this.inicia();

        
      },
            
 
      methods: {

        maxLength: function(max) {
          return [v => (v || '' ).length <= max || 'Máximo '+max+' caracteres']
        },



        //#####################
        //click dos botões da tela
        //####################
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
          
           
        }, 

        BtnSim(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        }, 


        sair(){

          this.$router.push('/');        

        },  

        

        //######################################
        //# Busca os contratos do participante
        //###################################### 

        inicia(){

          this.chamados = [];

          this.loading = true;


          
          this.$API.get( '/api/crm/chamados')
          .then(response => {

              this.chamados = response.data.chamados;
              this.assuntos = response.data.assuntos;
              this.loading = false;

          })
          .catch(error => {
              this.loading = false;
              this.TrataErros(error);
          });

            
        },

        
        
        exibedetalhes(id_chamado){

          this.loading = true;


          this.$API.get( '/api/crm/detalhes/'+id_chamado)
          .then(response => {

            this.exibe_detalhes = true;
            this.loading = false;      
            
            this.detalhes.descricao_macro = response.data.detalhes.descricao_macro;
            this.detalhes.assunto = response.data.detalhes.assunto;
            this.detalhes.descricao_detalhada = response.data.detalhes.descricao_detalhada;
            this.detalhes.resposta_serpros = response.data.detalhes.resposta_serpros;
            this.detalhes.created_at = response.data.detalhes.created_at;
            this.detalhes.numero = response.data.detalhes.numero;
            this.detalhes.situacao = response.data.detalhes.situacao;
            

          })
          .catch(error => {
              this.loading = false;
          });
          

        },

        novoChamado(){

          this.chamado.id_assunto = null;
          this.chamado.descricao_macro = '';
          this.chamado.descricao_detalhada = '';
          this.exibe_detalhes = false;
          this.titulo_pagina = 'Novo chamado';
          this.exibe_novo = true;

        },

        salvarNovoChamado(){

          this.form_valido = false;

          if(this.$refs.form.validate()){

            this.loading = true;

            this.$API.post( '/api/crm/novo', this.chamado)
            .then(response => {
                
                this.titulo_pagina = 'Meus chamados';
                this.exibe_novo = false;
                this.alerta = 'Chamado gravado com sucesso!';
                this.alerta_color = "green"; 
                this.chamado.id_assunto = null;
                this.chamado.descricao_macro = '';
                this.chamado.descricao_detalhada = '';  
                this.exibe_alerta = true; 
                this.inicia();

            })
            .catch(error => {
                this.loading = false;
                this.TrataErros(error);
            });

          }else{
            this.form_valido = true;
            this.overlay = false;
            this.titulo = 'Atenção';
            this.mensagem = 'Existem problemas no preenchimento. Favor revisar o formulário.';
            this.cor_dialogo ="#455A64";
            this.simnao = false;
            this.dialog = true;
          }

        },


        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        } 

      },
   }
</script>