import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store.js'

import Home from '@/pages/Home'
import Login from '@/pages/Login'
import Atendimento from '@/pages/Atendimento'
import PrimeiroAcesso from '@/pages/PrimeiroAcesso'
import EsqueciSenha from '@/pages/EsqueciSenha'
import AlterarEmail from '@/pages/alterarEmail'
import Contratacao from '@/pages/emprestimo/Contratacao'
import Extrato from '@/pages/emprestimo/Extrato'
import Informe from '@/pages/emprestimo/Informe'
import Regulamento from '@/pages/emprestimo/Regulamento'
import ChamaSimBnf from '@/pages/prev/ChamaSimBnf'
import SimBnf from '@/pages/prev/SimBnf'
import Requerimentos from '@/pages/requerimentos/ChamaSistemaRequerimento'
import ExtratoContrib from '@/pages/prev/ExtratoContrib'
import ExtratoBpa from '@/pages/prev/ExtratoBpa'
import Cadastro from '@/pages/prev/Cadastro'
import Recad from '@/pages/prev/Recad'
import Resgate from '@/pages/prev/Resgate'
import Autopatrocinio from '@/pages/prev/Autopatrocinio'
import in1343 from '@/pages/prev/in1343'
import Superavit from '@/pages/prev/Superavit'
import TrocaSenha from '@/pages/TrocaSenha'
import Contracheque from '@/pages/prev/Contracheque'
import InformeRendimentos from '@/pages/prev/Informe'
import AtualizacaoBpa from '@/pages/prev/AtualizacaoBpa'
import BoletoEsporadica from '@/pages/prev/BoletoEsporadica'
import ContratosInadimplentes from '@/pages/emprestimo/ContratosInadimplentes'
import ParcelasAtrasadas from '@/pages/emprestimo/ParcelasAtrasadas'
import ContratosAtivos from '@/pages/emprestimo/ContratosAtivos'
import ParcelasAntecipadas from '@/pages/emprestimo/ParcelasAntecipadas'
import Suspender from '@/pages/emprestimo/Suspender'
import AvisoRecad from '@/pages/AvisoRecad'
import Chamados from '@/pages/crm/Chamados'

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/login/:code?',
            name: 'login',
            component: Login
        },
        {
            path: '/atendimento',
            name: 'atendimento',
            component: Atendimento
        },
        {
          path: '/contratacao',
          name: 'contratacao',
          component: Contratacao
        },
        {
          path: '/simbnf/:tipo',
          name: 'simbnf',
          component: SimBnf
        },
        {
            path: '/chamasimbnf/:tipo?',
            name: 'chamasimbnf',
            component: ChamaSimBnf
          },
        {
          path: '/requerimentos',
          name: 'requerimentos',
          component: Requerimentos
        },
        {
            path: '/extrato',
            name: 'extrato',
            component: Extrato
        },
        {
            path: '/informe',
            name: 'informe',
            component: Informe
        },
        {
            path: '/regulamento',
            name: 'regulamento',
            component: Regulamento
        },
        {
            path: '/extratocontrib',
            name: 'extratocontrib',
            component: ExtratoContrib
        },
        {
            path: '/extratobpa',
            name: 'extratobpa',
            component: ExtratoBpa
        },
        {
            path: '/cadastro',
            name: 'cadastro',
            component: Cadastro
        },
        {
            path: '/resgate',
            name: 'resgate',
            component: Resgate
        },
        {
            path: '/autopatrocinio',
            name: 'autopatrocinio',
            component: Autopatrocinio
        },
        {
            path: '/in1343',
            name: 'in1343',
            component: in1343
        },
        {
            path: '/superavit',
            name: 'superavit',
            component: Superavit
        },
        {
            path: '/primeiroacesso',
            name: 'primeiroacesso',
            component: PrimeiroAcesso
        },
        {
            path: '/esquecisenha',
            name: 'esquecisenha',
            component: EsqueciSenha
        },
        {
            path: '/alteraremail',
            name: 'alteraremail',
            component: AlterarEmail
        },
        {
            path: '/trocasenha',
            name: 'trocasenha',
            component: TrocaSenha
        },
        {
            path: '/contracheque',
            name: 'contracheque',
            component: Contracheque
        },{
            path: '/informerendimentos',
            name: 'informerendimentos',
            component: InformeRendimentos
        },
        {
            path: '/atualizacaobpa',
            name: 'atualizacaobpa',
            component: AtualizacaoBpa
        },
        {
            path: '/recad',
            name: 'recad',
            component: Recad
        },
        {
            path: '/boletoesporadica',
            name: 'boletoesporadica',
            component: BoletoEsporadica
        },
        {
            path: '/contratosinadimplentes',
            name: 'contratosinadimplentes',
            component: ContratosInadimplentes
        },
        {
            path: '/parcelasatrasadas/:id_contrato_emp/:nr_contrato',
            name: 'parcelasatrasadas',
            component: ParcelasAtrasadas
        },
        {
            path: '/contratosativos',
            name: 'contratosativos',
            component: ContratosAtivos
        },
        {
            path: '/parcelasantecipadas/:id_contrato_emp/:nr_contrato',
            name: 'parcelasantecipadas',
            component: ParcelasAntecipadas
        },
        {
            path: '/suspender',
            name: 'suspender',
            component: Suspender
        },
        {
            path: '/avisorecad',
            name: 'avisorecad',
            component: AvisoRecad
        },
        {
            path: '/chamados',
            name: 'chamados',
            component: Chamados
        }


    ] 
});



router.beforeEach((to,from,next) => {

    //console.log(to);

    if (to.name !== 'login' && to.name !== 'primeiroacesso' && to.name !== 'esquecisenha' && to.name !== 'alteraremail' && to.name !== 'atendimento' && !store.state.usuario.logado ) {
      //console.log('Router jogou pro login');
      next({ name: 'login' });
    }else{
      //console.log('Router jogou pro '+to.name);
      if(to.name == 'login' || to.name == 'primeiroacesso' || to.name == 'esquecisenha' || to.name == 'alteraremail' || to.name == 'atendimento' || to.name == 'trocasenha' || to.name == 'recad' || to.name == 'avisorecad'|| to.name == 'chamados'){
        next();
      }else{
        //console.log(store.state.recad);
        if(store.state.recad == 4){

            next({ name: 'avisorecad' });
        }else{
            next();
        }
        
      } 


      
    }


  
});


export default router