<template>
  <div>
    <v-snackbar
    v-model="exibe_alerta"
    timeout="2000"
    :color="alerta_color"
    >
    {{ alerta }}
    </v-snackbar> 

    <v-container fluid >
      <v-row >
        <v-col >
          <!-- Esse card é a imagem de fundo -->
          <v-card
            class="rounded-0"
            flat
          >
            <v-img
              src="img/saco_dinheiro.png"
            >
              <v-toolbar flat dense tile >
                <v-app-bar-nav-icon class="d-lg-none ml-n6" @click.stop="$store.commit('switchSidebar');"></v-app-bar-nav-icon>
                <v-toolbar-title class="text-h5 ml-n5"  >{{titulo_pagina}}</v-toolbar-title>
              </v-toolbar>


              <v-row v-if="loading" class="mt-5">
                <v-col>
                  <div class="text-center" >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-col>
              </v-row>  
              <v-row v-if="loading" >
                <v-col>
                  <div class="text-center" >Carregando</div>
                </v-col>
              </v-row> 
            
              <!-- 
              ####################################################
              # Painel de entrada
              ####################################################
              -->


        
              <v-row v-if="!loading">
                <v-col cols="12" md="7" >
                  <v-card-text>   

                    <v-row v-if="etapa == 1 && planos.length > 1">
                      <v-col cols="12" md="5">
                        <div class="text-h5 font-weight-light">Plano/Situação</div>
                      </v-col>
                      <v-col cols="12" md="7">
                          <v-select
                            v-model="plano_opcao"
                            outlined
                            dense
                            :items="planos"
                            return-object
                            :rules="Rules.Required"
                            @change="TrocaPlano"
                            no-data-text="Carregando..."
                          >
                          <template slot="selection" slot-scope="data">
                            {{ data.item.sigla_plano }} - {{ data.item.situacao }}
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ data.item.sigla_plano }} - {{ data.item.situacao }}
                          </template>
                          </v-select>
                        
                      </v-col>
                    </v-row>   

                    <v-row v-if="etapa == 1 && erro_etapa1 ">
                      <v-col cols="12" >
                        <v-alert class="text-h5" text type="info" color="blue darken-4" v-for="msg in msg_erro_etapa1">{{ msg }}</v-alert>
                      </v-col>
                    </v-row> 

                    <v-row v-if="etapa == 1 && erro_limite_credito ">
                      <v-col cols="12" >
                        <v-alert class="text-h5" text type="info" color="blue darken-4" >Não é possível simular pois o limite de crédito do participante é inferior a um salário mínimo e não existem contratos que possam ser refinanciados no momento</v-alert>
                      </v-col>
                    </v-row> 

                    <v-row v-if="etapa == 1 && !erro_etapa1 && !erro_autorizacao_sisgcs && inadimplente && this.$store.state.permite_reforma=='S'">
                      <v-col cols="12" >
                        <v-alert class="text-h5" text type="info" color="blue darken-4" >Verificamos que você tem contratos com parcelas em atraso, por esse motivo não é possível solicitar um contrato novo, mas você tem a opção de refinanciar.</v-alert>
                      </v-col>
                    </v-row>  

                    <v-row v-if="etapa == 1 && !erro_etapa1 && !erro_autorizacao_sisgcs && contratos.length >= max_contratos && this.$store.state.permite_reforma=='S'">
                      <v-col cols="12" >
                        <v-alert class="text-h5" text type="info" color="blue darken-4" >Verificamos que você já possui {{max_contratos}} ou mais contratos, por esse motivo você não pode pegar um novo empréstimo, mas você pode refinanciar.</v-alert>
                      </v-col>
                    </v-row> 


                    <v-row v-if="etapa == 1 && contratos.length > 0 && !erro_etapa1 && !erro_autorizacao_sisgcs && !erro_limite_credito && this.$store.state.permite_reforma=='S'">
                      <v-col cols="12" md="7">
                        <div class="text-h5 font-weight-light">Deseja refinanciar?</div>
                      </v-col>
                    </v-row> 

                    <v-row v-if="etapa == 1 && !erro_etapa1 && contratos.length > 0 && this.$store.state.permite_reforma=='S'">
                      <v-col cols="12">
                        <v-data-table
                          :headers="headerContratos"
                          :items="contratos"
                          sort-by="dt_credito"
                          sort-desc
                          class="table"
                          no-data-text="Não há nenhum contrato"
                          :items-per-page="-1"
                          :loading="loading.anexo"   
                          loading-text="Carregando contratos..."
                          hide-default-footer
                          dense
                          item-key="nr_contrato"
                          >
                          
                          <template v-slot:item.dt_credito="{ item }">
                            <span>{{new Date(item.dt_credito).toLocaleString("pt-BR",{dateStyle: "short"})}}</span>
                          </template>

                          <template v-slot:item.vl_pmt="{ item }">
                            <span>{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(item.vl_pmt) }}</span>
                          </template>

                          <template v-slot:item.saldo="{ item }">
                            <span>{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(item.saldo) }}</span>
                          </template>


                          <template v-slot:[`item.actions`]="{ item }">
                              <v-row>
                                <v-checkbox
                                  v-model="item.selecionado"
                                  @change="CalculaValorRefinanciamento"
                                  dense
                                  hide-details
                                  class="mb-1"
                                  v-if="!item.carencia && !item.bloqueado"
                                ></v-checkbox>  

                                <v-tooltip bottom v-if="item.carencia">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon  color="orange"  v-bind="attrs" v-on="on">mdi-alpha-c-circle-outline</v-icon>
                                  </template>
                                  <span>Carência de {{item.dias_carencia}} dias para refinanciamento</span>
                                </v-tooltip>

                                <v-tooltip bottom v-if="item.bloqueado">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon  v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                                  </template>
                                  <span>Devido a impedimentos técnicos momentâneos, não é possível processar sua operação neste momento. Pedimos que tente novamente mais tarde</span>
                                </v-tooltip>
                                
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon  color="red"  v-if="item.inadimplente" v-bind="attrs" v-on="on">mdi-alpha-i-circle-outline</v-icon>
                                  </template>
                                  <span>Inadimplente</span>
                                </v-tooltip>
                              </v-row>
                          </template>




                        </v-data-table>
                      </v-col>
                    </v-row>   

                    <v-row v-if="etapa == 1 && !erro_etapa1 && !erro_autorizacao_sisgcs && !erro_limite_credito && contratos.length < max_contratos && (!inadimplente || (inadimplente && this.valor_refinanciado >0) ) ">
                      <v-col cols="12" md="7">
                        <div class="text-h5 font-weight-light">De quanto você precisa?</div>
                      </v-col>
                      <v-col cols="12"  md="5">
                        <div class="text-h4 font-weight-light ml-4 text-right text-no-wrap" v-text="Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(valor_liquido)" ></div>
                      </v-col>
                    </v-row>    


                    
                    <v-row v-if="etapa == 1 && !erro_etapa1 && !erro_autorizacao_sisgcs && !erro_limite_credito && contratos.length < max_contratos && (!inadimplente || (inadimplente && this.valor_refinanciado >0) ) ">
                      <v-col cols="12" v-if="!digitar_valor">
                        <v-slider
                          v-model="valor_liquido"
                          :min="0"
                          :max="etapa_1_max"
                          height="30"
                          :step="step_liquido"
                          @end="CalculaValorConcessao"
                          :disabled="calculando"
                        >

                        </v-slider>
                      </v-col>  



                      <v-col cols="12" v-if="digitar_valor">
                        <v-text-field
                          type="number"
                          hide-spin-buttons
                          reverse
                          outlined
                          dense
                          prefix=",00"
                          v-model="valor_liquido"
                          @keypress="valor_ok=false;msg_critica_valor='';SomenteNumeros()"
                          :rules="[...maxValorEmprestimo]"
                        >
                        </v-text-field>  
                      </v-col>
                    </v-row> 


                    <v-row v-if="etapa == 1 && erro_autorizacao_sisgcs">
                      <v-col cols="12" >
                        <v-alert class="text-h5" text type="info" color="blue darken-4">Não foi possível consultar o sistema de gestão de margem consignável. Por favor certifique-se de ter dado permissão ao Serpros para consultar sua margem consignável no <a target="_blank" href="https://consignacao.serpro.gov.br">SISGCS</a></v-alert>
                      </v-col>
                    </v-row> 


                    
                    
                    <v-row v-if="etapa == 1 && !erro_etapa1 && !erro_autorizacao_sisgcs && !erro_limite_credito">
                      <v-col cols="12" md="7">
                        <div class="text-h5 font-weight-light">Quer pagar em quantas parcelas?</div>
                      </v-col>
                      <v-col cols="12" md="5">
                        <div class="text-h4 font-weight-light ml-4 text-right" v-text="qtd_parcelas" ></div>
                      </v-col>
                    </v-row> 

                    <v-row v-if="etapa == 1 && !erro_etapa1 && !erro_autorizacao_sisgcs && !erro_limite_credito">
                      <v-col cols="12" v-if="!digitar_valor">
                        <v-slider
                          v-model="qtd_parcelas"
                          :min="min_parcelas"
                          :max="max_parcelas"
                          height="30"
                          @end="CalculaValorConcessao"
                          :disabled="loading2 || (valor_liquido + valor_refinanciado) == 0"
                        >

                        </v-slider>
                      </v-col>

                      <v-col cols="12" v-if="digitar_valor">
                        <v-text-field
                          type="number"
                          hide-spin-buttons
                          reverse
                          outlined
                          dense
                          v-model="qtd_parcelas"
                          @keypress="SomenteNumeros()"
                          :rules="[...maxParcelas]"
                        >
                        </v-text-field>  
                      </v-col>
                    </v-row> 



                    <v-row v-if="etapa == 1 && !erro_etapa1 && !erro_autorizacao_sisgcs && !erro_limite_credito && msg_critica_valor == ''">
                      <v-col cols="12" >
                        <v-card-actions>
                          <v-btn v-if="!digitar_valor && !calculando" color="primary" rounded dark @click="digitar_valor=true">Digitar valores</v-btn>
                          <v-btn v-if="digitar_valor && !calculando" color="primary" rounded dark @click="Voltar">Voltar</v-btn>
                          <v-btn v-if="!valor_ok && digitar_valor && !calculando" color="secondary" rounded dark @click="CalculaValorConcessao">Calcular</v-btn>
                          <v-btn v-if="valor_ok" color="green" rounded dark @click="etapa = 2;">Prosseguir</v-btn>
                        </v-card-actions>
                      </v-col>




                    </v-row>

                    <v-row v-if="etapa == 1 && msg_critica_valor != '' ">
                      <v-col cols="12" >
                        <v-alert class="text-h5" text type="info" color="blue darken-4">{{ msg_critica_valor }}</v-alert>
                      </v-col>
                    </v-row> 


                    


                    


                    <!-- 
                    ####################################################
                    # Painel com Dados Bancários
                    ####################################################
                    -->

                    <v-form 
                      ref="formBanco"
                      lazy-validation
                    >

                      <v-row v-if="etapa == 2 && msg_erro_etapa2" >
                        <v-col cols="12" >
                          <div class="text-justify text-h5 font-weight-light red--text text--darken-2">{{ msg_erro_etapa2 }}</div>
                        </v-col>
                      </v-row> 

                      <v-row v-if="etapa == 2 && !msg_erro_etapa2">
                        <v-col cols="12" md="5" >
                          <div class="text-h5 font-weight-light">Banco
                          </div>
                        </v-col>
                        <v-col cols="12" md="7" v-if="etapa == 2">
                          <v-select
                            v-model="banco"
                            outlined
                            dense
                            :items="$store.state.bancos_emprestimo"
                            item-value="numero"
                            :rules="Rules.Required"
                          >
                            <template slot="selection" slot-scope="data">
                              {{ data.item.numero }} - {{ data.item.nome }}
                            </template>
                            <template slot="item" slot-scope="data">
                              {{ data.item.numero }} - {{ data.item.nome }}
                            </template>
                          </v-select>
                        </v-col>
                      </v-row> 

                      <v-row v-if="etapa == 2 && !msg_erro_etapa2 && (banco =='260' || banco =='033')">
                        <v-col cols="12" >
                          <v-alert  class="text-center" dense text type="info"  color="blue darken-4" > Caso sua agência não possua DV, preencha com 0 (zero) </v-alert>
                        </v-col>
                      </v-row> 

                      <v-row v-if="etapa == 2 && !erro_codigo_validacao">
                        <v-col cols="12" md="5">
                          <div class="text-h5 font-weight-light">Agência
                          </div>
                        </v-col>

                        <v-col cols="12" md="5">
                          <v-text-field
                            outlined
                            dense
                            placeholder="Número"
                            v-model="agencia_numero"
                            :rules="[...Rules.Required, ...numeroAgencia, ...minLength]" 
                            maxlength="4"
                          >
                          </v-text-field> 
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-text-field
                            outlined
                            dense
                            placeholder="DV"
                            v-model="agencia_dv"
                            maxlength="2"
                            :rules="[...Rules.Required, ...numeroAgencia]" 
                          >
                          </v-text-field> 
                        </v-col>

  
                      </v-row> 

                      <v-row v-if="etapa == 2 && !msg_erro_etapa2">
                        <v-col cols="12" md="5" >
                          <div class="text-h5 font-weight-light">Conta corrente
                          </div>
                        </v-col>

                        <v-col cols="12" md="5">
                          <v-text-field
                            outlined
                            dense
                            placeholder="Número"
                            v-model="conta_numero"
                            :rules="[...Rules.Required, ...numeroConta]" 
                            maxlength="13"
                          >
                          </v-text-field> 
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-text-field
                            outlined
                            dense
                            placeholder="DV"
                            v-model="conta_dv"
                            maxlength="2"
                          >
                          </v-text-field> 
                        </v-col>
                      </v-row> 

                      <v-row v-if="etapa == 2 && !msg_erro_etapa2">
                        <v-col cols="12" md="12">
                          <v-btn class="mr-2" color="primary" rounded dark @click="VoltarEtapa(1)" :disabled="calculando">Voltar</v-btn>
                          <v-btn v-if="valor_bruto > 0 && agencia_numero !='' && agencia_dv !='' && conta_numero != ''" color="green" rounded dark @click="SalvarPrevia" :disabled="calculando">Prosseguir</v-btn>
                          
                        </v-col>

                      </v-row>

                      <v-row v-if="etapa == 2 && !msg_erro_etapa2">
                        <v-col cols="12" >
                          <div class="text-justify red--text text--darken-2" >NÃO SERÃO CONCEDIDOS EMPRÉSTIMOS MEDIANTE PREENCHIMENTO DE CONTA SALÁRIO E POUPANÇA.
                          </div>
                        </v-col>
                      </v-row> 

                      <v-row v-if="etapa == 2 && !msg_erro_etapa2">
                        <v-col cols="12" >
                          <div class="text-justify">Prezado participante, o contrato enviado ao SERPROS será analisado e, após aprovação, o empréstimo será concedido.
                          </div>
                        </v-col>
                      </v-row> 

                      <v-row v-if="etapa == 2 && !msg_erro_etapa2">
                        <v-col cols="12" >
                          <div class="text-justify">DÚVIDAS, PROBLEMAS OU DESISTÊNCIA DA CONTRATAÇÃO – Você pode cancelar sua simulação no menu Meus Contratos. Dúvidas, problemas ou caso o prazo para cancelamento pelo portal já tenha expirado, entre em contato com o Serviço de Atendimento ao Participante (SAP): 0800 721 10 10 ou pelo e-mail sap@serpros.com.br. O Horário de atendimento é de 08 a 17h.
                          </div>
                        </v-col>
                      </v-row> 
                    </v-form>


                    <!-- 
                    ####################################################
                    # Painel do regulamento
                    ####################################################
                    -->

                    <v-form 
                      ref="form"
                      lazy-validation
                    >

                      <v-row v-if="etapa == 3 && erro_etapa3" >
                        <v-col cols="12" >
                          <v-alert class="text-h5" text type="error" color="red darken-2">{{ msg_erro_etapa3 }}</v-alert>
                        </v-col>
                      </v-row> 

                      <v-row v-if="etapa == 3 && !erro_etapa3" >
                        <v-col cols="12" >
                          <div class="text-h5 grey--text text--darken-3">Veja aqui os termos do seu <a class="primary--text" href="#" @click="exibeContrato();">CONTRATO</a> de empréstimos.</div>
                        </v-col>
                      </v-row> 

                      <v-row v-if="etapa == 3 && !erro_etapa3" class="fill-height" >
                        <v-col cols="12" md="12" >
                            <v-checkbox v-model="leu_regulamento">
                              <template v-slot:label>
                                <div class="text-h5 grey--text text--darken-3">
                                  Declaro que li e aceito os termos do contrato e regulamento de empréstimo.
                                </div>
                              </template>
                            </v-checkbox>

                          <v-dialog
                            v-model="regulamento"
                            persistent
                          >
                            <v-card>
                              <v-card-title>
                              <v-spacer></v-spacer>
                               <v-btn
                                  text
                                  @click="regulamento = false"
                                >
                                  Fechar
                                </v-btn>
                              </v-card-title>
                              <v-card-text style="height:600px">     
                                <iframe :src="'data:Application/pdf;base64,'+ fileURL" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>
                              </v-card-text>
  
                            </v-card>
                          </v-dialog>
                        </v-col>
                      </v-row> 

                      <v-row v-if="etapa == 3 && !erro_etapa3">
                        <v-col cols="12" md="12">
                          <v-btn class="mr-2" color="primary" rounded dark @click="VoltarEtapa(1)" :disabled="calculando">Voltar</v-btn>
                          <v-btn v-if="leu_regulamento && !somente_leitura" color="green" rounded dark @click="enviarCodigo" :disabled="calculando">Quero Contratar!</v-btn> 
                          
                        </v-col>

                      </v-row>



                    </v-form>


                    <!-- 
                    ####################################################
                    # Painel com o input do código de confirmação
                    ####################################################
                    -->

                    <v-row v-if="etapa == 4 ">
                      <v-col cols="12" >
                        <v-alert class="text-h5 text-center" text type="info" color="#0D47A1" >{{ msg_codigo_validacao }}</v-alert>
                      </v-col>
                    </v-row> 

                    <v-row v-if="etapa == 4 ">
                      <v-col cols="12" >
                      <div class="ma-auto position-relative" style="max-width: 300px">
                        <v-otp-input
                          length="6"
                          type="number"
                          @finish="Confirmar"
                          v-model="codigo_confirmacao"
                          :disabled="calculando"
                        ></v-otp-input>
                      </div>  
                      </v-col>
                    </v-row> 

                    <v-row v-if="etapa == 4 && !calculando">
                      <v-col cols="12" >
                        <div class="text-center" >
                            <v-btn  dark text color="#0D47A1" @click="ReenviarCodigo" > Enviar novo código </v-btn>
                        </div>

                      </v-col>
                    </v-row> 

                    <v-row v-if="etapa == 4 && msg_erro_etapa4 != ''">
                      <v-col cols="12" >
                        <v-alert class="text-h5 text-center" text type="error" color="red darken-2">{{ msg_erro_etapa4 }}</v-alert>
                      </v-col>
                    </v-row> 


                     <!-- 
                    ####################################################
                    # Painel informando que o contrato foi finalizado
                    ####################################################
                    -->

                    <v-row v-if="etapa == 5">
                      <v-col cols="12" >

                        <!--   <div class="text-h5 font-weight-light text-justify"><b>Solicitação de empréstimo efetuada! Uma cópia do contrato foi enviada para o seu e-mail.</b>
                          </div> -->
                          <v-alert class="text-h5" text type="success" color="green darken-4">Solicitação de empréstimo efetuada! Uma cópia do contrato foi enviada para o seu e-mail. Número do contrato: {{ numero_contrato }}</v-alert>

                      </v-col>
                    </v-row> 

                    <v-row v-if="etapa == 6">
                      <v-col cols="12" >

                        <!--   <div class="text-h5 font-weight-light text-justify"><b>Solicitação de empréstimo efetuada! Uma cópia do contrato foi enviada para o seu e-mail.</b>
                          </div> -->
                          <v-alert class="text-h5" text type="error" color="red">Sua solicitação não foi confirmada porque o sistema SYSGCS não conseguiu consignar o contrato na folha depagamento do Serpro.</v-alert>

                      </v-col>
                    </v-row> 

                   <!--
                    <v-row v-if="etapa == 5">
                      <v-col cols="12" >

                           <div class="text-h5 font-weight-light text-justify">Contrato: {{ numero_contrato }}
                          </div>

                      </v-col>
                    </v-row> -->


                    <v-row v-if="calculando" class="mt-5">
                      <v-col>
                        <div class="text-center" >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </div>
                      </v-col>
                    </v-row>  
                    <v-row v-if="calculando" >
                      <v-col>
                        <div class="text-center" >Carregando</div>
                      </v-col>
                    </v-row> 




                  </v-card-text>
                </v-col>
                
                
                
                <!-- 
                ####################################################
                # Painel de resultado da simulação
                ####################################################
                -->
                
                <v-col cols="12" md="5">
                  <v-card 
                    class="mt-5 mr-5 cardTransparente"
                    outlined
                  >
                    <v-card-text>     

                      <v-row>
                        <v-col cols="12" md="7">
                          <span class="text-h5 font-weight-light ">Margem</span>
                        </v-col>
                        <v-col cols="12" md="5">
                          <div class="text-h5 font-weight-light mr-1 text-right text-no-wrap" v-text="Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(margem)"></div>
                        </v-col>
                      </v-row> 

                      <v-row>
                        <v-col cols="12" md="7">
                          <span class="text-h5 font-weight-light ">Limite de crédito</span>
                        </v-col>
                        <v-col cols="12" md="5">
                          <div class="text-h5 font-weight-light mr-1 text-right text-no-wrap" v-text="Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(valor_max_bruto)"></div>
                        </v-col>
                      </v-row> 

                      <v-row>
                        <v-col cols="12" md="7">
                          <span class="text-h5 font-weight-light ">Valor Líquido</span>
                        </v-col>
                        <v-col cols="12" md="5">
                          <div class="text-h5 font-weight-light mr-1 text-right text-no-wrap" v-text="Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(valor_liquido)"></div>
                        </v-col>
                      </v-row> 

                      <v-row>
                        <v-col cols="12" md="7">
                          <span class="text-h5 font-weight-light ">Refinanciamento</span>
                        </v-col>
                        <v-col cols="12" md="5">
                          <div class="text-h5 font-weight-light mr-1 text-right text-no-wrap" v-text="Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(valor_refinanciado)"></div>
                        </v-col>
                      </v-row> 
    
                      <v-row>
                        <v-col cols="12" md="7">
                          <span class="text-h5 font-weight-light ">I.O.F</span>
                        </v-col>
                        <v-col cols="12" md="5">
                          <div class="text-h5 font-weight-light mr-1 text-right text-no-wrap" v-text="Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(valor_iof)"></div>
                        </v-col>
                      </v-row> 

                      <v-row>
                        <v-col cols="12" md="7">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span 
                                v-bind="attrs"
                                v-on="on" 
                                class="text-h5 font-weight-light "
                              >
                                QQM 
                                <v-icon class="mt-n1">
                                  mdi-help-circle
                                </v-icon>
                              </span>
                            </template>
                            <span >
                                  Quota de Quitação Por Morte é a quota que garante a quitação <br>
                                  do saldo devedor do empréstimo, em caso de morte do devedor <br>
                                  que não esteja inadimplente
                            </span>
                          </v-tooltip>  
                        </v-col>
                        <v-col cols="12" md="5">
                          <div class="text-h5 font-weight-light mr-1 text-right text-no-wrap" v-text="Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(valor_qqm)"></div>
                        </v-col>
                      </v-row> 

                      <v-row>
                        <v-col cols="12" md="7">
                          <span class="text-h5 font-weight-light ">Correção</span>
                        </v-col>
                        <v-col cols="12" md="5">
                          <div class="text-h5 font-weight-light mr-1 text-right text-no-wrap" v-text="Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(valor_correcao)"></div>
                        </v-col>
                      </v-row> 


                      <v-row>
                        <v-col cols="12" md="7">
                          <span class="text-h5 font-weight-bold" >Valor da Parcela</span>
                        </v-col>
                        <v-col cols="12" md="5" >
                          <div class="text-center" v-if="calculando">
                            <v-progress-linear
                              class="mt-3"
                              indeterminate
                              color="grey"
                              striped
                              height="20"
                            ></v-progress-linear>
                          </div>  
                          <div v-if="!calculando" class="text-h5  font-weight-bold text-right text-no-wrap" v-text="Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(valor_parcela)"></div>
                        </v-col>
                      </v-row> 

                      <v-row>
                        <v-col cols="12" md="7">
                          <span class="text-h5 " >Valor Financiado</span>
                        </v-col>
                        <v-col cols="12" md="5" >
                          <div class="text-center" v-if="calculando">
                            <v-progress-linear
                              class="mt-3"
                              indeterminate
                              color="grey"
                              striped
                              height="20"
                            ></v-progress-linear>
                          </div>
                          <div v-if="!calculando" class="text-h5   text-right text-no-wrap" v-text="Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(valor_bruto)"></div>
                        </v-col>
                      </v-row> 



                      <v-row>
                        <v-col cols="12" md="7">
                          <span class="text-h5 " >Prazo</span>
                        </v-col>
                        <v-col cols="12" md="5" >
                          <div class="text-center" v-if="calculando">
                            <v-progress-linear
                              class="mt-3"
                              indeterminate
                              color="grey"
                              striped
                              height="20"
                            ></v-progress-linear>
                          </div>  
                          <div v-if="!calculando" class="text-h5   text-right text-no-wrap" v-text="qtd_parcelas"></div>
                        </v-col>
                      </v-row> 



                      <v-row>
                        <v-col cols="12" md="7">
                          <span class="text-h5" >Taxa de juros a.m.</span>
                        </v-col>
                        <v-col cols="12" md="5" >
                          <div class="text-center" v-if="calculando">
                            <v-progress-linear
                              class="mt-3"
                              indeterminate
                              color="grey"
                              striped
                              height="20"
                            ></v-progress-linear>
                          </div>
                          <div v-if="!calculando" class="text-h5   text-right text-no-wrap" v-text="Intl.NumberFormat('pt-BR',{style:'percent', currency:'BRL', minimumFractionDigits: 2}).format(taxa_aplicada/100)"></div>
                        </v-col>
                      </v-row> 
                      
                    </v-card-text>
                  </v-card>
                </v-col>
                
              </v-row>   
            


              <v-overlay
                absolute
                :value="dialog"
              >
              </v-overlay>

              <v-snackbar
                v-model="dialog"
                multi-line
                centered
                :color="cor_dialogo"
                timeout="-1"
                max-width="400"
                min-height="140"
                vertical
              >
                {{ mensagem }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnOk(titulo)"
                    v-if="!sim_nao"
                  >
                    Ok
                  </v-btn>
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnSim(titulo)"
                    v-if="sim_nao"
                  >
                    Sim
                  </v-btn>
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnNao(titulo)"
                    v-if="sim_nao"
                  >
                    Não
                  </v-btn>
                </template>
              </v-snackbar> 
            </v-img>
          </v-card>
        </v-col>
      </v-row>      
    </v-container>
  </div>
</template>

<script>
   export default {
      data: function(){
         return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            plano_opcao: null,
            titulo: "",
            titulo_pagina: "Contratação de Empréstimos",
            digitar_valor: false,
            form_valido: true,
            loading: true,
            loading2: false,
            loading_fator: false,
            regulamento: false,
            mensagem: '',
            dialog: false,
            cor_dialogo: '#B00020',
            exibe_alerta: false,
            alerta: '',
            alerta_color: "green",
            sim_nao: false,
            etapa: 1,
            idade: 0,
            valor_liquido: 0,
            valor_refinanciado: 0,
            parcela_refinanciada: 0,
            parcela_refinanciada_sisgcs: 0,
            parcela_refinanciada_todas: 0,
            etapa_1_max: 0,
            valor_max: 0,
            valor_max_bruto: 0,
            valor_iof: 0,
            valor_iof_montante: 0,
            valor_iof_adicional: 0,
            valor_qqm: 0,
            valor_correcao: 0,
            valor_bruto: 0,
            qtd_parcelas: 1,
            min_parcelas: 1,
            max_parcelas: 60,
            valor_parcela: 0,
            taxa_juros: 0,
            taxa_iof: 0,
            taxa_iof_dia: 0,
            data_referencia:null,
            qtd_contrib: 0,
            salario: 0,
            beneficio: 0,
            salario_minimo: 0,
            saldo: 0,
            margem: 0,
            margem_bruta: 0,
            pmt_total: 0,
            tabela_qqm: [],
            dia_venc_parcela: 0,
            contratos: [],
            taxa_aplicada: 0,
            carencia: 0,
            calculando: false,
            banco: '',
            agencia_numero: "",
            agencia_dv: "",
            conta_numero: "",
            conta_dv: "",
            id_pessoa_participante: null,
            sigla_setor: '',
            dia_limite: null,
            inadimplente: false,
            erro_etapa1: false,
            erro_etapa2: false,
            erro_etapa3: false,
            erro_etapa4: false,
            erro_limite_credito: false,
            msg_erro_etapa1: [],
            msg_erro_etapa2: '',
            msg_erro_etapa3: '',
            msg_erro_etapa4: '',
            erro_autorizacao_sisgcs: false,
            erro_codigo_validacao: false,
            msg_codigo_validacao: false,
            msg_critica_valor: '',
            codigo_enviado: false,
            codigo_confirmacao: null,
            numero_contrato: null,
            id_contrato_web: null,
            valor_ok: false,
            fileURL: null,
            countdown: 0,
            mostra_snack: false,
            max_contratos: 0,
            erro_carencia: false,
            menor_de_idade: false,
            margem_inicial: 0,
            max_liquido_inicial: 0,
            max_bruto_inicial: 0,
            step_liquido: 500,
            leu_regulamento: false,
            fileURL: null,
            tem_contrato_sem_carencia: false,
            headerContratos: [
                {
                    text: 'Contrato',
                    align: 'start',
                    sortable: false,
                    value: 'nr_contrato',
                    width: 40,
                    class: 'title white--text'
                },
                {
                    text: 'Data',
                    value: 'dt_credito',
                    sortable: false,
                    width: 70,
                    class: 'title white--text'
                },
                {
                    text: 'Prazo',
                    value: 'nr_prazo',
                    sortable: false,
                    width: 20,
                    class: 'title white--text'
                },
                {
                    text: 'Parcela',
                    value: 'vl_pmt',
                    sortable: false,
                    width: 70,
                    class: 'title white--text'
                },
                {
                    text: 'Saldo',
                    value: 'saldo',
                    sortable: false,
                    width: 70,
                    class: 'title white--text'
                },
                {
                    text: '',
                    value: 'actions',
                    align: 'end',
                    sortable: false,
                    width: 60,
                    class: 'title white--text'
                }
            ],
            planos: [],
            somente_leitura: true,
         }
      },



      created: function() {

        

        if(this.$store.state.atendente == null){
          this.somente_leitura = false;
        }

        let params = {
            api: this.$API,
            descricao: this.titulo_pagina
        }

        this.$store.dispatch('logatendimento', params);

        var texto = '';
        this.$store.dispatch('exibeajuda',texto);

        if(this.$store.state.alerta != ''){
           this.alerta = this.$store.state.alerta;
           this.$store.commit('setalerta','');
           this.exibe_alerta = true;
        }

        this.planos = this.planos_emprestimo();

        if(this.planos.length > 0){
          this.plano_opcao = this.planos[0];
        }

        this.iniciaSimulador();

        
      },



            
 
      methods: {

        //#####################
        //REGRAS DE VALIDACAO
        //####################

        minLength: function(valor) {
          
          let aprovado = true;
          let mensagem = '';

          if(valor.length < 4 ){
             aprovado = false;
             mensagem = 'Mínimo 4 caracteres. Se necessário preencha com um zero a esquerda.' ; 
          }

          return  aprovado || mensagem
        },

        maxValorEmprestimo: function(valor) {
          
          let aprovado = true;
          let mensagem = '';

          if(this.valor_liquido > this.valor_max ){
             aprovado = false;
             mensagem = 'Valor máximo: '+ (Math.floor(this.valor_max * 100) / 100).toFixed(2) ; 
          }

          return  aprovado || mensagem
        },

        maxParcelas: function(valor) {
          
          let aprovado = true;
          let mensagem = '';

          if(this.qtd_parcelas > this.max_parcelas ){
             aprovado = false;
             mensagem = 'Prazo máximo: '+ this.max_parcelas ; 
          }

          return  aprovado || mensagem
        },

        numeroAgencia: function() {
          
          let aprovado = true;
          let mensagem = '';

          let tamanho = this.agencia_numero.length + this.agencia_dv.length;

          if( tamanho > 5){
             aprovado = false;
             mensagem = 'Numero + DV máximo 5 caracteres' ; 
          }

          return  aprovado || mensagem
        },

        numeroConta: function() {
          
          let aprovado = true;
          let mensagem = '';

          let tamanho = this.conta_numero.length + this.conta_dv.length;

          if( tamanho > 15){
             aprovado = false;
             mensagem = 'Numero + DV máximo 15 caracteres' ; 
          }

          return  aprovado || mensagem
        },



        //#####################
        //click dos botões da tela
        //####################
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 

        BtnSim(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        }, 


        sair(){

          this.$router.push('/');        

        }, 
        
        Voltar(){

          this.digitar_valor=false;
          this.valor_liquido = 0;
          this.CalculaValorConcessao();

        },

        //######################################
        //# Filtros
        //###################################### 

        planos_emprestimo(){

          let planos = this.$store.state.planos_usuario.filter(plano => plano.situacao == "ATIVO" || plano.situacao == "ASSISTIDO" || plano.situacao == "SALDADO" || plano.situacao == "PENSIONISTA");
          let len = planos.length;
          for(let i=0; i<len; i++){
            planos[i].id_opcao = i+1;
          }

          return planos;

        },

        //######################################
        //# Inicia o simulador
        //###################################### 

        iniciaSimulador(){
          
          if(this.plano_opcao != null){
            
            var params = {
              id_pessoa: this.plano_opcao.id_pessoa,
              id_plano: this.plano_opcao.id_plano,
              qtd_parcelas: this.qtd_parcelas,
              plano_situacao: this.plano_opcao.situacao,
              id_pessoa_titular: this.plano_opcao.id_pessoa_titular,
            }
            
            this.erro_etapa1 = false;
            this.msg_erro_etapa1 = [];
            this.erro_autorizacao_sisgcs = false; 
            this.loading = true;
            
            this.$API.post( '/api/emprestimo/inicia', params)
            .then(response => {

                this.numero_contrato = null;
                this.id_contrato_web = null;

                this.idade = response.data.idade;
                this.qtd_contrib = response.data.qtd_contrib;
                this.margem = response.data.margem;
                this.contratos =  response.data.contratos; 
                this.valor_max = Math.floor(response.data.max_liquido);
                this.valor_max_bruto = response.data.max_bruto;
                this.max_parcelas = response.data.max_parcelas;  
                this.qtd_parcelas = response.data.max_parcelas;  
                this.sigla_setor = response.data.parametros_emprestimo.sigla_setor;
                this.dia_limite = response.data.parametros_emprestimo.dia_limite_primeira_parcela;
                this.inadimplente = response.data.inadimplente;
                this.max_contratos = response.data.max_contratos;
                this.etapa_1_max = this.valor_max;

                this.margem_inicial = response.data.margem;
                this.max_liquido_inicial = Math.floor(response.data.max_liquido);
                this.max_bruto_inicial = response.data.max_bruto;
                this.salario_minimo = response.data.salario_minimo;
                
                if(response.data.erro_sisgcs == true){
                  this.erro_autorizacao_sisgcs = true;
                  this.margem = 0;
                  
                }


                var cobranca_judicial = 'N';
                this.tem_contrato_sem_carencia = false;
                
                for (var i=0; i < this.contratos.length; i++)  {
                    this.contratos[i].selecionado =false;
                    if(this.contratos[i].ic_controle_judicial == 'S'){
                        cobranca_judicial = 'S';
                    }
                    if(!this.contratos[i].carencia){
                        this.tem_contrato_sem_carencia = true;
                    }
                }


                if(this.valor_max < this.salario_minimo && !this.tem_contrato_sem_carencia){

                  //this.erro_limite_credito = true;

                }

                if(this.max_parcelas <= 0){

                  this.erro_etapa1 = true;
                  this.msg_erro_etapa1.push("Não é possível simular pois o prazo máximo calculado é zero meses.");

                }

                if(this.idade < 18 ){

                      this.erro_etapa1 = true;
                      this.msg_erro_etapa1.push("A idade mínima para contratação de empréstimos é 18 anos.");

                }
                

                if(this.plano_opcao.situacao == 'ATIVO' || this.plano_opcao.situacao == 'SALDADO'){
                  if(this.qtd_contrib < 12){

                    this.erro_etapa1 = true;
                    this.msg_erro_etapa1.push("Para contratar um empréstimo o participante deverá ter no mínimo 12 contribuições pagas.");

                  }
                }

                if(this.plano_opcao.situacao == 'AUTOPATROCÍNIO' ){

                    this.erro_etapa1 = true;
                    this.msg_erro_etapa1.push("A simulação de empréstimos não está disponível para participantes autopatrocinados.");

                }

                if(this.plano_opcao.situacao == 'BPD' ){

                    this.erro_etapa1 = true;
                    this.msg_erro_etapa1.push("A simulação de empréstimos não está disponível para participantes em BPD.");

                }

                if(this.plano_opcao.situacao == 'AUXÍLIO DOENÇA' ){

                    this.erro_etapa1 = true;
                    this.msg_erro_etapa1.push("A simulação de empréstimos não está disponível para participantes em auxílio-doença.");

                }

                if(this.plano_opcao.situacao == 'ACIDENTE DE TRABALHO' ){

                    this.erro_etapa1 = true;
                    this.msg_erro_etapa1.push("A simulação de empréstimos não está disponível para participantes em acidente de trabalho.");

                }


                if(cobranca_judicial == 'S'){

                  this.erro_etapa1 = true;
                  this.msg_erro_etapa1.push("Não é possível simular pois existem contratos em cobrança judicial.");

                }

                if(response.data.precisa_recadastrar){

                  this.erro_etapa1 = true;
                  this.msg_erro_etapa1.push("Não é possível simular pois seu cadastro está desatualizado. Atualize seu cadastro acessando o menu Cadastro/Dados pessoais.");

                }

                if(response.data.existe_simulacao_pendente){

                  this.erro_etapa1 = true;
                  this.msg_erro_etapa1.push("Existe uma simulação confirmada que ainda não foi creditada. Aguarde o crédito em conta para realizar uma nova simulação.");

                }

                    
                this.loading = false;  

                alert("Os contratos solicitados até às 14h do dia 12/11 serão concedidos no dia 14/11. Os contratos solicitados após às 14h do dia 12/11 até às 14h do dia 22/11, serão concedidos no dia 26/11. ");                    

                
                
            })
            .catch(error => {
                this.TrataErros(error);
            }); 

            

          }else{
            this.erro_etapa1 = true;
            this.msg_erro_etapa1.push("A simulação de empréstimos não está disponível para participantes em auxílio-doença, acidente de trabalho, autopatrocinados, desligados ou em BPD.");
            this.loading = false;
          }

            

        },





        //######################################
        //# CALCULA OS VALORES PARA CONCESSAO
        //###################################### 
        CalculaValorConcessao(){

          this.calculando = true;
          this.valor_ok = false;
          this.msg_critica_valor = "";

          if(this.valor_liquido + this.valor_refinanciado > 0){

              //var plano = this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado];


              var params = {
                id_pessoa: this.plano_opcao.id_pessoa,
                id_plano: this.plano_opcao.id_plano,
                plano_situacao: this.plano_opcao.situacao,
                prazo: this.qtd_parcelas,
                valor_liquido: this.valor_liquido,
                valor_refinanciado: this.valor_refinanciado,
                contratos: this.contratos,
                id_pessoa_titular: this.plano_opcao.id_pessoa_titular
              }
              
              
              this.valor_iof = 0;
              this.valor_qqm = 0;
              this.valor_correcao = 0;
              this.valor_bruto = 0;
              this.valor_parcela = 0;
              this.taxa_aplicada = 0;


              this.$API.post( '/api/emprestimo/calcula', params)
              .then(response => {
                  
                  this.valor_iof = response.data.iof_total;
                  this.valor_iof_montante = response.data.iof;
                  this.valor_iof_adicional = response.data.iof_adicional;
                  this.valor_qqm = response.data.seguro;
                  this.valor_correcao = response.data.valor_financiado - response.data.valor_solicitado;
                  this.valor_bruto = response.data.valor_financiado;
                  this.valor_parcela = response.data.prestacao;
                  this.taxa_aplicada = response.data.taxa_aplicada;
                  this.margem = response.data.margem;
                  this.valor_max = Math.floor(response.data.max_liquido);
                  this.valor_max_bruto = response.data.max_bruto;
                  this.etapa_1_max = this.valor_max;

                 


                  if( response.data.retorno_valida_solicitacao != "ok" ){
                    this.valor_ok = false; 
                    this.msg_critica_valor = response.data.retorno_valida_solicitacao;
                  }else{
                    this.valor_ok = true;
                  }

                  if(this.valor_max < this.salario_minimo && !this.tem_contrato_sem_carencia){
                    this.erro_limite_credito = true;
                  }else{
                    this.erro_limite_credito = false;
                  }

                  this.calculando = false;


              }) 
              .catch(error => {
                  this.TrataErros(error);
              });   

          }else{

              this.valor_iof = 0;
              this.valor_iof_montante = 0;
              this.valor_iof_adicional = 0;
              this.valor_qqm = 0;
              this.valor_correcao = 0;
              this.valor_bruto = 0;
              this.valor_parcela = 0;
              this.taxa_aplicada = 0;

              this.margem = this.margem_inicial;
              this.valor_max = this.max_liquido_inicial;
              this.valor_max_bruto = this.max_bruto_inicial;
              this.etapa_1_max = this.valor_max;
       
              this.calculando = false;
              
          }

          

        },


        //######################################
        //# Salva o novo contrato nas tabelas da web pra mostrar o pdf
        //###################################### 
        SalvarPrevia(){

            if( this.$refs.formBanco.validate() ){

                this.msg_erro_etapa3 = "";


                var params = {
                  id_pessoa: this.plano_opcao.id_pessoa,
                  id_pessoa_titular: this.plano_opcao.id_pessoa_titular,
                  id_plano: this.plano_opcao.id_plano,
                  plano_situacao: this.plano_opcao.situacao,
                  data_referencia: this.data_referencia,
                  prazo: this.qtd_parcelas,
                  valor_liquido: this.valor_liquido,
                  valor_refinanciado: this.valor_refinanciado,
                  contratos: this.contratos,
                  banco: this.banco,
                  agencia: this.agencia_numero,
                  agencia_dv: this.agencia_dv, 
                  conta: this.conta_numero+this.conta_dv,
                  id_beneficio: this.plano_opcao.id_beneficio
                }


              
                
                this.loading = true;  
                this.etapa = 3;
                this.$API.post( '/api/emprestimo/salvarweb', params)
                .then(response => {
                    if(response.data.codigo == 'erro'){
                       this.erro_etapa3 = true;
                       this.msg_erro_etapa3 = response.data.mensagem;
                       
                    }else{
                       this.numero_contrato = response.data.nr_contrato;
                       this.id_contrato_web = response.data.id_contrato_web;
                    }

                    this.loading = false;

                })
                .catch(error => {
                    this.TrataErros(error);
                });  




            }
        },

        
        
        //######################################
        //# Salva o novo contrato
        //###################################### 
        Confirmar(){

            if( this.$refs.form.validate() ){

                this.msg_erro_etapa4 = "";

                var params = {
                  id_pessoa: this.plano_opcao.id_pessoa,
                  plano_situacao: this.plano_opcao.situacao,
                  contratos: this.contratos,
                  codigo_confirmacao: this.codigo_confirmacao,
                  id_contrato_web: this.id_contrato_web,
                }

              

                this.calculando = true;
                this.$API.post( '/api/emprestimo/salvarb', params) 
                .then(response => {
                    if(response.data.codigo == 'erro'){
                       this.etapa = 6;
                       this.codigo_confirmacao = "";
                    }else if(response.data.codigo == 'codigo_invalido'){
                       this.codigo_confirmacao = "";
                       this.msg_erro_etapa4 = "Código de confirmação inválido!";
                    }else{
                       this.etapa = 5;
                       this.codigo_confirmacao = "";
                       this.enviaContrato();

                    }

                    this.calculando = false;

                }) 
                .catch(error => {
                    this.TrataErros(error);
                }); 




            }
        },


        

        //######################################
        //# permite o input de numeros inteiros apenas
        //###################################### 
        SomenteNumeros: function(evt) {
          this.valor_ok=false;
          this.msg_critica_valor='';

          evt = (evt) ? evt : window.event;
          let expect = evt.target.value.toString() + evt.key.toString();
          
          if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
            evt.preventDefault();
          } else {
            return true;
          }
        },


        //######################################
        //# Volta pra etapa anterior
        //###################################### 
        VoltarEtapa(etapa) {

            if(etapa == 1){

              this.erro_etapa4 = false;
              this.erro_etapa3 = false;
              this.erro_etapa1 = false;
              
            }

            if(etapa == 2){

              this.erro_etapa4 = false;
              this.erro_etapa3 = false;
              this.erro_etapa1 = false;
              
            }

            this.etapa = etapa;
        },



        //######################################
        //# Envia codigo de confirmação para o email ou celular
        //###################################### 
        enviarCodigo(){

            if( this.$refs.form.validate() ){

                this.calculando = true;
                this.$API.get( '/api/emprestimo/gerarcodigo') 
                .then(response => {
                    if(response.data.codigo == 'erro'){
                       this.codigo_enviado = false;
                       this.erro_codigo_validacao = true;
                       this.erro_etapa3 = true;
                       this.msg_erro_etapa3 = response.data.mensagem;
                    }else{
                       this.etapa = 4;
                       if(this.mostra_snack){
                          this.exibe_alerta = true;
                          this.alerta_color = "green";
                          this.alerta = "Novo código de confirmação enviado";

                       }
                    }

                    this.mostra_snack = false;
                    this.msg_codigo_validacao = response.data.mensagem;
                    this.calculando = false;
                    
                    
                }) 
                .catch(error => {
                    this.TrataErros(error);
                });  




            }
        },




        //######################################
        //# Soma o valor dos contratos que serão refinanciados
        //###################################### 
        CalculaValorRefinanciamento(){


            this.erro_carencia = false;

            this.valor_refinanciado = 0;
            this.parcela_refinanciada = 0;
            this.parcela_refinanciada_sisgcs = 0;
            this.parcela_refinanciada_todas = 0;

            for(var i=0; i < this.contratos.length; i++){

                this.parcela_refinanciada_todas = this.parcela_refinanciada_todas + parseFloat(this.contratos[i].vl_pmt);

                if(this.contratos[i].selecionado){
                    this.valor_refinanciado = this.valor_refinanciado + parseFloat(this.contratos[i].saldo);
                    this.parcela_refinanciada = this.parcela_refinanciada + parseFloat(this.contratos[i].vl_pmt);
                    if(this.contratos[i].existe_sisgcs =="S"){
                      this.parcela_refinanciada_sisgcs = this.parcela_refinanciada_sisgcs + parseFloat(this.contratos[i].parcela_sisgcs);
                    }
                }
            }

            this.CalculaValorConcessao();
            

        },






        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }else{
                this.mensagem = error.response.data.mensagem;
              }
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        },

        ReenviarCodigo(){
          this.mostra_snack = true;
          this.msg_erro_etapa4 = "";
          this.codigo_confirmacao = "";
          this.enviarCodigo();

        },

        TrocaPlano(){
          this.iniciaSimulador();

        },



        exibeContrato(){


          this.loading = true;
          this.mensagem = '';

          this.$API.get( '/api/emprestimo/pdfcontrato/'+this.numero_contrato)
          .then(response => {

            this.fileURL = response.data;
            this.regulamento = true;
            this.loading = false;             

          })
          .catch(error => {
              this.loading = false;
          });
          

        },


        enviaContrato(){


          var params = {
            id_contrato_emp: this.id_contrato_web,
          }

          this.$API.post( '/api/emprestimo/enviacontrato', params);
          

        },

      },
   }
</script>