<template>

    <v-navigation-drawer
      v-model="$store.state.sidebar"
      app
      color="primary"
      v-if="$store.state.usuario.logado"
    >

      <router-link to="/">
         <v-img
          alt="Serpros Fundo Multipatrocinado"
          :src="path+imagem"
          max-height="120"
          contain
          
        />
      </router-link>

      <v-list  dark>
        <template v-for="item in $store.state.menu">
          <!-- Este grupo é exibido somente se o item possuir filhos -->
          <v-list-group
            v-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
            active-class="menu-active"
          >
            <!-- Este template exibe o título do item que possui filhos -->
            <template v-slot:activator>
              <v-list-item-content >
                <v-list-item-title> {{ item.text }} </v-list-item-title>
              </v-list-item-content>
            </template>
            <!-- Fim do Template -->
            
            <!-- Estes são os filhos (o segundo nível do menu) -->
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              link
              @click="Roteador(item, child)"
              active-class="submenu-active"
              class="submenu mt-n1"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          <!-- Fim dos filhos -->  

          </v-list-group>
          <!-- Fim do grupo que é exibido somente se o item possuir filhos -->

          <!-- Estes são os itens que não possuem filhos -->
          <v-list-item
            v-else
            :key="item.text"
            link
            :to="item.path"

          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Fim dos itens que não possuem filhos -->
        </template>

        <v-list-item
          link
          @click="sair"  
        >
          <v-list-item-action>
            <v-icon>mdi-exit-run</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Sair
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-container fluid >

      <div v-html="$store.state.ajuda"> </div>

      </v-container>

    </v-navigation-drawer>
</template>

<script>
  export default {
    data: function(){
      return {  
          path: process.env.VUE_APP_URL,
          imagem: '/img/serpros-logo-branco.png',
      }
    },
    methods: {
      sair(){

        let params = {
            api: this.$API,
            descricao: 'O atendente saiu'
        }

        this.$store.dispatch('logatendimento', params); 

        var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

        this.$store.commit('setusuariologado', usuario);
        this.$store.commit('setatendente', null);

        this.$store.commit('setpessoafisica',null);
        this.$store.commit('setsituacao',null);
        this.$store.commit('setnuvem',null);
        this.$store.commit('setalerta','');
        this.$store.commit('setidpessoa',null);
        this.$store.commit('setidemp',null);
        this.$store.commit('setplanoparticipante',null);
        this.$store.commit('setplanoselecionado', 0);
        this.$store.commit('sethistplano', []);


        this.$router.push('/login');
        

      }, 

      Roteador(item, child){
        if(child.tipo == 'I'){
          this.$router.push(child.path);
        }else{
            /* LOYO */
          /*  if(child.text === 'CDE') {
                alert("Caros Participantes e Assistidos,\n\nInformamos que a publicação das atas referentes às recentes reuniões extraordinárias (1ª, 3ª, 4ª e 5ª Reuniões Extraordinárias do CDE de 2024, ocorridas nos meses de fevereiro, março e abril de 2024) será adiada. Esta decisão se baseia na natureza das negociações em andamento, temporariamente classificadas como confidenciais, atendendo à orientação da Diretoria Executiva.\n\nEntendemos a importância da transparência e do acesso à informação para todos os envolvidos. No entanto, acreditamos ser essencial garantir que as negociações sejam concluídas sob a máxima discrição, para assegurar os melhores resultados possíveis.\n\nAssim que as negociações forem finalizadas e as informações puderem ser compartilhadas, comprometemo-nos a disponibilizar as atas para consulta.\n\nAgradecemos a compreensão e paciência de todos durante este período.\n\nCordialmente,\n\nPaulo Mendonça Júnior\n\nPresidente do Conselho Deliberativo")
            } */
            /* LOYO */
          window.open(child.path, '_blank').focus();
        }

        item.model=false;
      }
    }
  }        
</script>        